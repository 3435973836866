import React, { Fragment, useState, useEffect } from "react";
import NavBar from "../../components/navbar/NavBar";
import NotAuthPage from "../NotAuth/NotAuthPage";
import { useParams } from "react-router-dom";
import { notAdmin } from "../../util/roles";
import { useSelector } from "react-redux";
import LOCsMap from "../../components/LOCsMap";
import LOCsPanel from "../../components/LOCsMap/LOCsPanel";
import { notAuthError, url } from "../../constants";
import { separateOriginFromDest } from "../../components/LOCsMap/utils/separateOriginFromDest";
import { toast, ToastContainer } from "react-toastify";
import { locTypes } from "../../util/constants";
import "react-toastify/dist/ReactToastify.css";
import { separateFields } from "../../components/LOCsMap/utils/separateFields";
import LegendsPanel from "../../components/LOCsMap/LegendsPanel";
import { useLoadScript } from "@react-google-maps/api";
const libraries = ["places"];

const LocationDetailedMapPage = () => {
  const { token, role, organization } = useSelector((state) => state.login);
  const { id } = useParams();

  const { isLoaded } = useLoadScript({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyDqQoL7de2pQIUiTbiawgZSogGW2rmUZrg",
    libraries,
  });

  const [mapDataLoading, setMapDataLoading] = useState(true);
  const [switchLOCsPanel, setSwitchLOCsPanel] = useState(false);
  const [switchLegendsPanel, setSwitchLegendsPanel] = useState(false);

  const [mapData, setMapData] = useState({
    LOCs: [],
    location: {
      gid: "",
      id: "",
      latitude: 0,
      longitude: 0,
    },
  });
  const [selectedLocs, setSelectedLocs] = useState([]);
  const [selectedLocTypes, setSelectedLocTypes] = useState([]);
  const [selectedLegends, setSelectedLegends] = useState([]);
  const [fields, setFields] = useState({
    field_1: {},
    field_2: {},
    field_3: {},
  });
  const [selectedField, setSelectedField] = useState("");

  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");

  const handleSelectLOC = (loc) => {
    const isSelected = selectedLocTypes.includes(loc);

    const selectedList = isSelected
      ? selectedLocTypes.filter((type) => type !== loc)
      : [...selectedLocTypes, loc];

    let filteredList = [];

    // console.log(selectedList);

    if (selectedField === "") {
      selectedList.forEach((item) => {
        let [status, type, place] = item.split(" - ");
        place = place || "";
        let newList = [...mapData.LOCs].filter(
          (obj) =>
            obj.status === status && obj.type === type && obj.place === place
        );
        if (selectedCategory !== "") {
          newList = newList.filter(
            (obj) => obj.category === selectedCategory.id
          );
        }
        filteredList = [...filteredList, ...newList];
      });
    } else {
      selectedList.forEach((item) => {
        let [status, type, place] = item.split(" - ");
        place = place || "";
        const newList = [...mapData.LOCs].filter(
          (obj) =>
            obj.status === status &&
            obj.type === type &&
            obj.place === place &&
            (selectedLegends.includes(obj.field_1) ||
              selectedLegends.includes(obj.field_2) ||
              selectedLegends.includes(obj.field_3))
        );
        filteredList = [...filteredList, ...newList];
      });
    }

    setSelectedLocs(filteredList);
    setSelectedLocTypes(selectedList);
  };
  const handleSelectLegend = (legend) => {
    // const newLegends = [...selectedLegends];
    // const index = selectedLegends.indexOf(legend);
    // index !== -1 ? newLegends.splice(index, 1) : newLegends.push(legend);
    // setSelectedLegends(newLegends)
    const isSelected = selectedLegends.includes(legend);

    const selectedList = isSelected
      ? selectedLegends.filter((type) => type !== legend)
      : [...selectedLegends, legend];

    let filteredList = [];

    // console.log(selectedList);
    selectedList.forEach((item) => {
      let field = item;
      const newList = [...mapData.LOCs].filter(
        (obj) =>
          (obj.field_1 === field ||
            obj.field_2 === field ||
            obj.field_3 === field) &&
          selectedLocTypes.includes(
            `${obj.status} - ${obj.type}${
              obj.place !== "" ? ` - ${obj.place}` : ``
            }`
          )
      );
      filteredList = [...filteredList, ...newList];
    });

    setSelectedLocs(filteredList);
    setSelectedLegends(selectedList);
  };

  const handleSelectField = (field) => {
    setSelectedField(field);
    setSelectedLegends(Object.keys(fields[field]));
    setSelectedLocTypes([...locTypes]);
    setSelectedLocs([...mapData.LOCs]);
  };

  const handleSelectCategory = (category) => {
    setSelectedCategory(categories.find((cat) => cat.id === category));
    setSelectedLocs(mapData.LOCs.filter((loc) => loc.category === category));
    setSelectedLocTypes(locTypes);
  };

  const resetFilters = () => {
    setSelectedField("");
    setSelectedLegends([]);
    setSelectedLocs(mapData.LOCs);
    setSelectedCategory("");
    setSelectedLocTypes(locTypes);
  };

  useEffect(() => {
    setMapDataLoading(true);
    fetch(`${url}/api/locations/${id}/get-locs-for-location-map`, {
      headers: { Authorization: `Bearer ${token}`, web: true },
    })
      .then((res) => res.json())
      .then((resData) => {
        if (resData.error) {
          if (resData.error === notAuthError) {
            toast("you session expired, please login again.");
          } else {
            toast.error(resData.error);
          }
          setMapDataLoading(false);
        } else {
          const LOCsByFields = separateFields(resData.LOCs);
          setFields(LOCsByFields);
          const newLOCs = separateOriginFromDest(resData.LOCs);
          const data = { ...resData, LOCs: newLOCs };

          setMapData(data);
          setSelectedLocs(newLOCs);
          setSelectedLocTypes(locTypes);

          setMapDataLoading(false);
        }
      });
  }, []);

  useEffect(() => {
    fetch(
      `${url}/api/organizations/categories?organization=${organization}&location=${id}`,
      {
        headers: { Authorization: `Bearer ${token}`, web: true },
      }
    )
      .then((res) => res.json())
      .then((resData) => {
        if (resData.error) {
          if (resData.error === notAuthError) {
            toast("your session expired, please login again.");
          } else {
            toast.error(resData.error);
          }
        } else {
          setCategories(resData.categories);
        }
      });
  }, []);

  if (!notAdmin.includes(role)) return <NotAuthPage />;
  return (
    <Fragment>
      <NavBar withMargin={false} />
      <ToastContainer />

      <div className="position-relative">
        <LOCsMap
          LOCs={selectedLocs}
          info={mapData.location}
          loading={mapDataLoading}
          fields={fields}
          selectedField={selectedField}
          selectedLegends={selectedLegends}
          isMapLoaded={isLoaded}
          selectedCategory={selectedCategory}
        />
        <LOCsPanel
          selectedLocTypes={selectedLocTypes}
          switchPanel={switchLOCsPanel}
          setSwitchPanel={setSwitchLOCsPanel}
          handleSelect={handleSelectLOC}
        />
        <LegendsPanel
          categories={categories}
          fields={fields}
          selectedLegends={selectedLegends}
          switchPanel={switchLegendsPanel}
          setSwitchPanel={setSwitchLegendsPanel}
          handleSelect={handleSelectLegend}
          selectedField={selectedField}
          handleSelectField={handleSelectField}
          handleSelectCategory={handleSelectCategory}
          resetFilters={resetFilters}
        />
      </div>
    </Fragment>
  );
};
export default LocationDetailedMapPage;
