import { useEffect } from "react";
import LegendCheckbox from "./LegendCheckbox";
import { useSelector } from "react-redux";

const LegendsPanel = ({
  switchPanel,
  setSwitchPanel,
  fields,
  categories,
  selectedLegends,
  handleSelect,
  handleSelectField,
  selectedField,
  handleSelectCategory,
  selectedCategory,
  resetFilters,
}) => {
  return (
    <div
      className={`position-absolute ${
        switchPanel ? "h-auto" : "h-45"
      } bg-white p-1`}
      style={{
        border: "2px solid black",
        top: "8px",
        right: "8px",
        width: "300px",
      }}
    >
      <div className="d-flex justify-between items-center py-0 px-2 my-2">
        <div className="form-group form-check">
          <input
            onClick={() => {
              resetFilters();
              setSwitchPanel(!switchPanel);
            }}
            checked={switchPanel}
            type="checkbox"
            className="form-check-input"
            id="switch-panel"
          />
          <label
            className="form-check-label"
            htmlFor="switch-panel"
            style={{ fontSize: "12px" }}
          >
            Filter By Category
          </label>
        </div>
        <div>
          {!switchPanel ? (
            <>
              <select
                className="form-control"
                onChange={(e) => handleSelectField(e.target.value)}
                value={selectedField}
              >
                <option value="" disabled={true}>
                  select a field
                </option>
                <option value="field_1">Field 1</option>
                <option value="field_2">Field 2</option>
                <option value="field_3">Field 3</option>
              </select>
            </>
          ) : (
            <>
              <select
                className="form-control"
                onChange={(e) => handleSelectCategory(e.target.value)}
                value={selectedCategory}
              >
                <option value="" disabled={true}>
                  select a category
                </option>
                {categories.map((category) => (
                  <option value={category.id}>{category.name}</option>
                ))}
              </select>
            </>
          )}
        </div>
      </div>
      <hr className="mb-0" style={{ borderWidth: "1px" }} />
      {!switchPanel &&
        selectedField &&
        Object.keys(fields[selectedField]).map((legend, index) => (
          <LegendCheckbox
            index={index}
            legend={legend}
            handleSelect={handleSelect}
            selectedLegends={selectedLegends}
          />
        ))}
    </div>
  );
};

export default LegendsPanel;
