export const locInitialState = {
  singleLocForm: {
    unique_asset_id: {
      value: "",
      valid: false,
      validation: {
        required: true,
      },
      validationError: "Required",
      touched: false,
    },
    actual_asset_name: {
      value: "",
      valid: false,
      validation: {
        required: true,
      },
      validationError: "Required",
      touched: false,
    },
    notes: {
      value: "",
      valid: false,
      validation: {
        required: false,
      },
      validationError: "Required",
      touched: false,
    },

    filed1: {
      value: "",
      valid: false,
      validation: {
        required: true,
      },
      validationError: "Required",
      touched: false,
    },
    filed2: {
      value: "",
      valid: false,
      validation: {
        required: true,
      },
      validationError: "Required",
      touched: false,
    },
    filed3: {
      value: "",
      valid: false,
      validation: {
        required: true,
      },
      validationError: "Required",
      touched: false,
    },
    origin_latitude: {
      value: "",
      valid: false,
      validation: {
        required: false,
      },
      validationError: "Required",
      touched: false,
    },
    origin_longitude: {
      value: "",
      valid: false,
      validation: {
        required: false,
      },
      validationError: "Required",
      touched: false,
    },
    origin_radius: {
      value: 10,
      valid: false,
      validation: {
        required: false,
      },
      validationError: "Required",
      touched: false,
    },
    origin_status: {
      value: "unassigned",
      valid: false,
      validation: {
        required: true,
      },
      validationError: "Required",
      touched: false,
    },
    category: {
      value: null,
      valid: true,
      validation: {
        required: false,
      },
      validationError: "Required",
      touched: false,
    },
    fields: {
      value: {},
      valid: false,
      validationError: "Required",
      touched: false,
    }
  },

  dualLocForm: {
    unique_asset_id: {
      value: "",
      valid: false,
      validation: {
        required: true,
      },
      validationError: "Required",
      touched: false,
    },
    origin_status: {
      value: "unassigned",
      valid: false,
      validation: {
        required: true,
      },
      validationError: "Required",
      touched: false,
    },
    destination_status: {
      value: "unassigned",
      valid: false,
      validation: {
        required: true,
      },
      validationError: "Required",
      touched: false,
    },
    cableOrigin: {
      value: "",
      valid: false,
      validation: {
        required: true,
      },
      validationError: "Required",
      touched: false,
    },
    filed1Origin: {
      value: "",
      valid: false,
      validation: {
        required: true,
      },
      validationError: "Required",
      touched: false,
    },
    filed2Origin: {
      value: "",
      valid: false,
      validation: {
        required: true,
      },
      validationError: "Required",
      touched: false,
    },
    filed3Origin: {
      value: "",
      valid: false,
      validation: {
        required: true,
      },
      validationError: "Required",
      touched: false,
    },
    origin_latitude: {
      value: "",
      valid: false,
      validation: {
        required: false,
      },
      validationError: " must be a number",
      touched: false,
    },
    origin_longitude: {
      value: "",
      valid: false,
      validation: {
        required: false,
      },
      validationError: " must be a number",
      touched: false,
    },
    origin_radius: {
      value: 20,
      valid: false,
      validation: {
        required: true,
      },
      validationError: "Required",
      touched: false,
    },
    cableDestination: {
      value: "",
      valid: false,
      validation: {
        required: true,
      },
      validationError: "Required",
      touched: false,
    },
    filed1Destination: {
      value: "",
      valid: false,
      validation: {
        required: true,
      },
      validationError: "Required",
      touched: false,
    },
    filed2Destination: {
      value: "",
      valid: false,
      validation: {
        required: true,
      },
      validationError: "Required",
      touched: false,
    },
    filed3Destination: {
      value: "",
      valid: false,
      validation: {
        required: true,
      },
      validationError: "Required",
      touched: false,
    },

    notes: {
      value: "",
      valid: false,
      validation: {
        required: false,
      },
      validationError: "Required",
      touched: false,
    },

    lat: {
      value: 0,
      valid: false,
      validation: {
        required: true,
        // isNumber: true,
      },
      validationError: "Required must be a number",
      touched: false,
    },
    long: {
      value: 0,
      valid: false,
      validation: {
        required: true,
      },
      validationError: "Required must be a number",
      touched: false,
    },
    radius: {
      value: "",
      valid: false,
      validation: {
        required: true,
      },
      validationError: "Required must be a number",
      touched: false,
    },
    category: {
      value: null,
      valid: true,
      validation: {
        required: false,
      },
      validationError: "Required",
      touched: false,
    },
    fields: {
      value: {},
      valid: false,
      validationError: "Required",
      touched: false,
    },
    destination_fields: {
      value: {},
      valid: false,
      validationError: "Required",
      touched: false,
    },
  },

  loading: false,

  singleLocs: [],
  dualLocs: [],
  loadingLocs: false,

  loadDelete: false,

  specificLoc: {},
  loadSpecificLoc: false,

  searchSingleLocs: [],
  searchDualLocs: [],
  loadEdit: false,

  renderedItem: "locs",

  loadUpload: false,
};
