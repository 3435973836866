import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  onChangeLocationInputs,
  onEditingLocation,
  onFetchingSpecificLocation,
} from "../../store/Locations/LocationsReducers";

import { PropagateLoader } from "react-spinners";
import { css } from "@emotion/react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { notAuthError, url } from "../../constants";
import EditLocationMap from "../Map/EditLocationMap";
import useLocationUsersContainer from "../../containers/useLocationUsersContainer";
import roles from "../../util/roles";
import useThirdParty from "../../hooks/thirdParty/useThirdParty";

const styleLinkBack = {
  textDecoration: "none",
  color: "#717993",
  fontSize: "22px",
};

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const EditLocation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [disableBtn, setDisableBtn] = useState(false);
  const { id } = useParams();
  const { token, userId, role } = useSelector((state) => state.login);

  const [globalIdentifier, setGlobalIdenetifier] = useState(null);
  const [project, setProject] = useState(null);
  const [radius, setRadius] = useState(2000);
  const [orgId, setOrgId] = useState(null);

  // const [location , setLocation] = useState(null)
  const [gid, setGid] = useState("");
  const { locationForm, specificLocation, loadEditLocation } = useSelector(
    (state) => state.locations
  );

  useEffect(() => {
    dispatch(onFetchingSpecificLocation(id, token, navigate));
  }, [dispatch]);

  useEffect(() => {
    if (specificLocation && specificLocation.id) {
      dispatch(onChangeLocationInputs(specificLocation.name, "name"));
      dispatch(onChangeLocationInputs(specificLocation.latitude, "lat"));
      dispatch(onChangeLocationInputs(specificLocation.longitude, "long"));
      dispatch(onChangeLocationInputs(specificLocation.radius, "radius"));
      dispatch(onChangeLocationInputs(specificLocation.privacy, "privacy"));
    }
  }, [specificLocation, dispatch]);

  const {
    assignedUsers,
    setAssignedUsers,
    onAssignUser,
    users,
    loading: assignedUsersLoading,
  } = useLocationUsersContainer(specificLocation?.id);

  const loggedInThirdParty = localStorage.getItem("thirdParty_id");
  let filteredUsers = users;
  if(loggedInThirdParty) {
    filteredUsers = users.filter(user => loggedInThirdParty === user.thirdParty_id);
  }

  const { thirdParties, loading: thirdPartiesLoading } = useThirdParty(
    orgId,
    !!orgId
  );

  const loggedInUser = localStorage.getItem("userId");

  const filteredThirdParties = thirdParties.filter(thirdParty => users.some(user => user.thirdParty_id === thirdParty.id))

  const [assignedThirdParties, setAssignedThirdParties] = useState([]);


  useEffect(() => {
    fetch(`${url}/api/locations/${id}`, {
      headers: { Authorization: `Bearer ${token}`, web: true },
    })
      .then((res) => res.json())
      .then((resData) => {
        if (resData.error) {
          if (resData.error === notAuthError) {
            toast("you session expired, please login again.");
          } else {
            toast.error(resData.error);
          }
        } else {
          setGlobalIdenetifier(resData.globalIdentifier);
          setProject(resData.project);
          setGid(resData.globalIdentifier.gid);
          setOrgId(resData.globalIdentifier.org_id);
        }
      });
  }, [id, token]);

  useEffect(() => {
    let assignedUsersData = users.filter(user => assignedUsers.includes(user.user_id));

    const alreadyAssignedThirdParties = assignedUsersData.reduce((acc, user) => {
      if(user.thirdParty_id && !acc.includes(user.thirdParty_id)) {
        acc.push(user.thirdParty_id)
      }
      return acc;
    }, []);

    setAssignedThirdParties(alreadyAssignedThirdParties);
  }, [users, assignedUsers]);

  const onAssignThirdParty = (thirdPartyId) => {
    let usersInThirdParty = users.filter(user => user.thirdParty_id === thirdPartyId);
    usersInThirdParty = usersInThirdParty.map(user => {
      return user.user_id
    })
    if(!assignedThirdParties.includes(thirdPartyId)) {
      setAssignedThirdParties([...assignedThirdParties, thirdPartyId]);
      setAssignedUsers(prevState => {
        let updatedState = [...prevState];
        usersInThirdParty.forEach(userId => {
          const index = updatedState.findIndex(item => item === userId);
          if(index > -1) {
            // updatedState.splice(index, 1);
          } else {
            updatedState.push(userId);
          };
        })
        return updatedState;
      })
    } else {
      setAssignedThirdParties(assignedThirdParties.filter((thirdParty) => thirdParty !== thirdPartyId));
      setAssignedUsers(prevState => {
        let updatedState = [...prevState];
        usersInThirdParty.forEach(userId => {
          const index = updatedState.findIndex(item => item === userId);
          if(index > -1) {
            updatedState.splice(index, 1);
          }
          //  else {
          //   updatedState.push(userId);
          // };
        })
        return updatedState;
      })
    }
  };



  return (
    <Fragment>
      <ToastContainer />
      <div className="container">
        {globalIdentifier?.name && project?.name ? (
          <Fragment>
          <Link
            to={"/globalidenetifiers"}
            style={styleLinkBack}
          >
            Global Identifiers
          </Link>
          <span className="mx-2" style={{ color: "#28345C" }}>
            <i className="fas fa-chevron-right"></i>
            <i className="fas fa-chevron-right"></i>
          </span>
            <Link
              to={"/globalidenetifiers/projects/" + globalIdentifier.gid}
              style={styleLinkBack}
            >
              {globalIdentifier.name}
            </Link>
            <span className="mx-2" style={{ color: "#28345C" }}>
              <i className="fas fa-chevron-right"></i>
              <i className="fas fa-chevron-right"></i>
            </span>
            <Link to={"/locations/" + project.id} style={styleLinkBack}>
              {project.name}
            </Link>
            <span className="mx-2" style={{ color: "#28345C" }}>
              <i className="fas fa-chevron-right"></i>
              <i className="fas fa-chevron-right"></i>
            </span>
            <Link
              to={"/editlocation/" + specificLocation.id}
              style={styleLinkBack}
            >
              {specificLocation.name}
            </Link>
          </Fragment>
        ) : null}
        {/* <Link to={"/gid"} style={styleLinkBack}>
          GID 1
        </Link>
        <span className="mx-2" style={{ color: "#28345C" }}>
          <i className="fas fa-chevron-right"></i>
          <i className="fas fa-chevron-right"></i>
        </span>
        <Link to={"/gid"} style={styleLinkBack}>
          EDIT LOCATION 1
        </Link> */}
        <div className="row">
          <div className="col-10 col-md-6 m-auto">
            <h3 className="text-center my-3 text-uppercase">EDIT LOCATION</h3>
            <form
              onSubmit={(e) =>
                dispatch(
                  onEditingLocation(
                    e,
                    id,
                    token,
                    locationForm.name.value,
                    locationForm.lat.value,
                    locationForm.long.value,
                    locationForm.radius.value,
                    locationForm.privacy.value,
                    assignedUsers,
                    gid,
                    navigate
                  )
                )
              }
            >
              <input
                type="text"
                className="form-control"
                placeholder="Edit Location Name"
                onChange={(e) =>
                  dispatch(onChangeLocationInputs(e.target.value, "name"))
                }
                value={locationForm.name.value}
                style={
                  locationForm.name.valid ? {} : { border: "1px solid red" }
                }
              />
              {locationForm.name.valid ? null : (
                <div style={{ color: "red", fontSize: 14 }}>
                  {locationForm.name.validationError}
                </div>
              )}
              {/* <div className="bg-light mt-3">Map</div> */}

              {/* <ProjectsMap radius={radius} />   */}
              <EditLocationMap
                radius={locationForm.radius.value}
                locationLat={locationForm.lat.value}
                locationLong={locationForm.long.value}
              />
              <div className="row my-3">
                <div className="col">
                  <input
                    type="text"
                    className="form-control"
                    name="Latitude"
                    placeholder="Latitude"
                    disabled={true}
                    value={locationForm.lat.value}
                    onChange={(e) =>
                      dispatch(
                        onChangeLocationInputs(Number(e.target.value), "lat")
                      )
                    }
                    style={true ? {} : { border: "1px solid red" }}
                  />
                  {true ? null : (
                    <div style={{ color: "red", fontSize: 14 }}>
                      {locationForm.lat.validationError}
                    </div>
                  )}
                </div>
                <div className="col">
                  <input
                    type="text"
                    className="form-control"
                    name="Longitude"
                    placeholder="Longitude"
                    disabled={true}
                    value={locationForm.long.value}
                    onChange={(e) =>
                      dispatch(
                        onChangeLocationInputs(Number(e.target.value), "long")
                      )
                    }
                    style={true ? {} : { border: "1px solid red" }}
                  />
                  {true ? null : (
                    <div style={{ color: "red", fontSize: 14 }}>
                      {locationForm.long.validationError}
                    </div>
                  )}
                </div>
              </div>
              <div
                style={{
                  width: "90%",
                  display: "flex",
                  // justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <input
                  type="number"
                  step={1}
                  min={0}
                  className="form-control"
                  name="Radius"
                  placeholder="Radius"
                  id="Radius"
                  value={locationForm.radius.value}
                  onChange={(e) =>
                    dispatch(onChangeLocationInputs(e.target.value, "radius"))
                  }
                  style={
                    locationForm.radius.valid ? {} : { border: "1px solid red" }
                  }
                />
                <span style={{ marginLeft: 5 }}>Meter</span>
              </div>
              {locationForm.radius.valid ? null : (
                <div style={{ color: "red", fontSize: 14 }}>
                  {locationForm.radius.validationError}
                </div>
              )}

              <div className="flex items-center mt-2">
                <input
                  onChange={(e) => {
                    if (locationForm.privacy.value === "private") {
                      dispatch(onChangeLocationInputs("public", "privacy"));
                      if(assignedUsers.includes(loggedInUser) && filteredUsers.some(user => user.user_id === loggedInUser)){
                        onAssignUser(loggedInUser);
                      }
                    } else {
                      dispatch(onChangeLocationInputs("private", "privacy"));
                      if(!assignedUsers.includes(loggedInUser) && filteredUsers.some(user => user.user_id === loggedInUser)){
                        onAssignUser(loggedInUser);
                      }
                    }
                  }}
                  checked={locationForm.privacy.value === "private"}
                  id="checked-checkbox"
                  type="checkbox"
                  className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label
                  htmlFor="checked-checkbox"
                  className="ml-2 text-sm font-medium"
                >
                  Private
                </label>
              </div>
              {!assignedUsersLoading &&
                users.length > 0 &&
                locationForm.privacy.value === "private" &&
                specificLocation &&
                (specificLocation.User.user_id === userId ||
                  role === roles.super_admin ||
                  role === roles.super_user ||
                  role === roles.saas_admin) && (
                  <div className="mt-4">
                    {filteredThirdParties.length > 0 ? (
                      <>
                        <h6>Assigned Third Parties</h6>

                        <div
                            className="mt-2"
                            style={{ height: "100px", overflowY: "scroll" }}
                          >
                            {filteredThirdParties.map((thirdParty) => (
                              <>
                                {role === roles.saas_admin ||
                                role === roles.super_user ||
                                role === roles.super_admin ? (
                                  <div
                                    key={thirdParty.user_id}
                                    className="flex items-center my-2 mr-2"
                                  >
                                    <input
                                      onChange={() => onAssignThirdParty(thirdParty.id)}
                                      checked={assignedThirdParties.includes(thirdParty.id)}
                                      id={thirdParty.id}
                                      type="checkbox"
                                      className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                      htmlFor={thirdParty.id}
                                      className="ml-2 text-sm font-medium"
                                    >
                                      {thirdParty.name}
                                    </label>
                                  </div>
                                ) : (
                                  <label
                                    htmlFor="checked-checkbox"
                                    className="text-sm font-medium"
                                  >
                                    {locationForm.privacy.value === "private"
                                      ? "Private"
                                      : "Public"}
                                  </label>
                                )}
                              </>
                            ))}
                          </div>
                      </>
                    ): ""}
                    <h6>Assigned Users</h6>

                    <div
                      className="mt-2"
                      style={{ height: "150px", overflowY: "scroll" }}
                    >
                      {filteredUsers.map((user) => (
                        <>
                          {role === roles.saas_admin ||
                          role === roles.super_user ||
                          role === roles.super_admin ||
                          specificLocation.User.user_id === userId ? (
                            <div
                              key={user.user_id}
                              className="flex items-center my-2 mr-2"
                            >
                              <input
                                onChange={() => onAssignUser(user.user_id)}
                                checked={assignedUsers.includes(user.user_id)}
                                id={user.user_id}
                                type="checkbox"
                                className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                              />
                              <label
                                htmlFor={user.user_id}
                                className="ml-2 text-sm font-medium"
                              >
                                {user.fullName}
                              </label>
                            </div>
                          ) : (
                            <label
                              htmlFor="checked-checkbox"
                              className="text-sm font-medium"
                            >
                              {locationForm.privacy.value === "private"
                                ? "Private"
                                : "Public"}
                            </label>
                          )}
                        </>
                      ))}
                    </div>
                  </div>
                )}

              {users.length === 0 &&
                locationForm.privacy.value === "private" &&
                specificLocation &&
                (specificLocation.User.user_id === userId ||
                  role === roles.super_admin ||
                  role === roles.super_user ||
                  role === roles.saas_admin) && (
                  <p className="fw-bold my-2 text-center">
                    there are no users in this global idenetifier
                  </p>
                )}

              <div className="row my-3 m-auto">
                <div className="col d-flex justify-content-center">
                  <button
                    disabled={
                      disableBtn
                        ? disableBtn
                        : !(
                            locationForm.name.valid &&
                            locationForm.lat.valid &&
                            locationForm.long.valid &&
                            locationForm.radius.valid
                          )
                    }
                    type="submit"
                    className="btn btn-primary w-25"
                    style={{ width: "130px", height: "40px" }}
                    onClick={() => {
                      setTimeout(() => {
                        setDisableBtn(true);
                      }, 1);
                      setTimeout(() => {
                        setDisableBtn(false);
                      }, 2000);
                    }}
                  >
                    {loadEditLocation ? (
                      <PropagateLoader
                        color={"#fff"}
                        css={override}
                        size={10}
                      />
                    ) : (
                      "Save"
                    )}
                  </button>
                </div>
                {/* <div className="col d-flex justify-content-center">
                  <button type="submit" className="btn btn-secondary w-50">
                    Cancel
                  </button>
                </div> */}
              </div>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default EditLocation;
