import { Link } from "react-router-dom";
import { useState } from "react";
import { toast } from "react-toastify";
import { app } from "../../config/firebase-config";
import { getStorage, getDownloadURL, ref } from "firebase/storage";
import Modal from "react-modal";
import styles from "./../project/Project.module.css";

import { ReactComponent as EditIcon } from "../../ui/icons/EditIcon.svg";
import { ReactComponent as DeleteIcon } from "../../ui/icons/DeleteIcon.svg";
import { ReactComponent as RecycleBinIcon } from "../../assets/images/recycle-bin-empty_svgrepo.com.svg";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    paddingBottom: "0%",
  },
};

function AssignedLocsTable({
  role,
  searchSingleLocs,
  searchValue,
  singleLocs,
  selectedSingleLocs,
  onSelectAllSingleLocs,
  onSelectLoc,
  sortTable,
  sortType,
  locSorted,
  renderedItem,
  notUser,
  setSingleLocID,
  setDeleteSingleIsOpen,
  setRestoreSingleIsOpen,
  isBin,
  notAdminOrUser,
  handleOpenLockModal,
  loadSearch,
  globalSearch,
  fields,
}) {
  const loggedInUserId = localStorage.getItem("userId");
  const loggedInThirdPartyId = localStorage.getItem("thirdParty_id");

  const [imageGalleryIsOpen, setImageGalleryIsOpen] = useState(false);
  const [imageLoc, setImageLoc] = useState(null);
  const [imageIndex, setImageIndex] = useState(0);

  function sortObjectByArray(obj, sortArray) {
    console.log(obj);
    // Create a Map from the original object
    const map = new Map(Object.entries(obj));

    // Sort the keys based on their index in the sortArray
    const sortedEntries = Array.from(map).sort((a, b) => {
      return sortArray.indexOf(a[0]) - sortArray.indexOf(b[0]);
    });

    // Create a new object from the sorted entries
    return Object.fromEntries(sortedEntries);
  }

  function downloadFile(attachments) {
    if (!Array.isArray(attachments)) {
      attachments = [attachments];
    }

    const storage = getStorage(app);
    Promise.all(
      attachments.map((att) => {
        return getDownloadURL(ref(storage, att));
      })
    ).then((urls) => {
      urls.forEach((url) => {
        fetch(url)
          .then((response) => {
            return response.blob();
          })
          .then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.download = `${blob.type.split("/")[0]}-${Math.random()
              .toString(36)
              .substring(7)}.${blob.type.split("/")[1]}`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
          })
          .catch((error) => toast.error("Error downloading attachment"));
      });
    });
  }
  return (
    <>
      <table
        className="table m-0"
        style={{ fontSize: "12px", verticalAlign: "middle" }}
      >
        <thead className="sticky z-10 top-0">
          <tr className="bg-primary text-white text-uppercase">
            {role !== "user" && (
              <th
                className="whitespace-nowrap sticky left-0 bg-primary"
                scope="col"
              >
                {(searchSingleLocs.length === 0 && searchValue.trim() !== "") ||
                singleLocs.length === 0 ? (
                  "Select"
                ) : (
                  <input
                    id="default-checkbox"
                    type="checkbox"
                    checked={
                      selectedSingleLocs.toString() ===
                      singleLocs.map((loc) => loc.loc_id).toString()
                    }
                    onChange={() =>
                      onSelectAllSingleLocs(singleLocs.map((loc) => loc.loc_id))
                    }
                    className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 focus:ring-2"
                  />
                )}
              </th>
            )}
            {globalSearch && (
              <>
                <th className="whitespace-nowrap" scope="col">
                  GID
                </th>
                <th className="whitespace-nowrap" scope="col">
                  PROJECT
                </th>
                <th className="whitespace-nowrap" scope="col">
                  LOCATION
                </th>
              </>
            )}
            <th
              className="whitespace-nowrap"
              scope="col"
              onClick={() => sortTable("single", "unique_asset_id")}
            >
              Unique Asset ID
              <i
                className={`text-white far fa-arrow-${
                  sortType === "unique_asset_id" && locSorted === "single"
                    ? "up"
                    : "down"
                } text-black mx-2`}
              ></i>
            </th>
            <th
              className="whitespace-nowrap"
              scope="col"
              onClick={() => sortTable("single", "actual_asset_name")}
            >
              Actual Asset Name
              <i
                className={`text-white far fa-arrow-${
                  sortType === "actual_asset_name" && locSorted === "single"
                    ? "up"
                    : "down"
                } text-black mx-2`}
              ></i>
            </th>
            {fields.map((field, index) => (
              <th className="whitespace-nowrap" scope="col">
                {field}
              </th>
            ))}
            <th className="whitespace-nowrap" scope="col" colSpan="2">
              LOCATION
            </th>
            {/* <th scope="col">LATITUDE</th>
                        <th scope="col">LONGITUDE</th>
                        <th scope="col">RADIUS</th> */}
            <th
              className="whitespace-nowrap"
              scope="col"
              onClick={() => sortTable("single", "updatedAt")}
            >
              LAST UPDATE
              <i
                className={`text-white far fa-arrow-${
                  sortType === "updatedAt" && locSorted === "single"
                    ? "up"
                    : "down"
                } text-black mx-2`}
              ></i>
            </th>
            <th
              className="whitespace-nowrap"
              scope="col"
              onClick={() => sortTable("single", "email")}
            >
              LAST UPDATED BY
              <i
                className={`text-white far fa-arrow-${
                  sortType === "email" && locSorted === "single" ? "up" : "down"
                } text-black mx-2`}
              ></i>
            </th>
            <th className="whitespace-nowrap" scope="col">
              NOTES
            </th>
            <th className="whitespace-nowrap" scope="col">
              IMAGES
            </th>
            {/* <th scope="col">USERS INITIALS</th> */}
            {/* <th scope="col">Soft Locked</th> */}
            {/* <th className="whitespace-nowrap" scope="col">
              Hard Locked
            </th> */}
            {role !== "user" && (
              <th className="whitespace-nowrap" scope="col">
                Actions
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          {renderedItem === "locs" ? (
            singleLocs.map((loc) => {
              return (
                <tr key={loc.loc_id}>
                  {role !== "user" && (
                    <td scope="row" className="sticky left-0 bg-white">
                      <input
                        id="default-checkbox"
                        type="checkbox"
                        checked={selectedSingleLocs.includes(loc.loc_id)}
                        onChange={() => onSelectLoc(loc.loc_id, "single")}
                        className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 focus:ring-2"
                      />
                    </td>
                  )}
                  {globalSearch && (
                    <>
                      <td className="font-bold">
                        <Link
                          className="text-primary"
                          to={`/globalidenetifiers/projects/${loc?.Location?.Project?.GlobalIdentifier?.gid}`}
                        >
                          {loc?.Location?.Project?.GlobalIdentifier?.name}
                        </Link>
                      </td>
                      <td className="font-bold">
                        <Link
                          className="text-primary"
                          to={`/locations/${loc?.Location?.Project?.id}`}
                        >
                          {loc?.Location?.Project?.name}
                        </Link>
                      </td>
                      <td className="font-bold">
                        <Link
                          className="text-primary"
                          to={`/viewlocs/${loc?.Location?.id}`}
                        >
                          {loc?.Location?.name}
                        </Link>
                      </td>
                    </>
                  )}
                  <td>{loc.unique_asset_id}</td>
                  <td>{loc.actual_asset_name}</td>
                  {Object.keys(sortObjectByArray(loc.fields, fields)).map(
                    (field) => (
                      <td key={field}>{loc.fields[field]}</td>
                    )
                  )}
                  {/* <td>{loc.field_1}</td>
                  <td>{loc.field_2}</td>
                  <td>{loc.field_3}</td> */}
                  {/* <td className="d-flex justify-content-center">
                                <button
                                  type="button"
                                  className="btn btn-primary btn-sm w-75"
                                >
                                  view
                                </button>
                              </td> */}
                  <td>
                    <span style={{ fontSize: "9px" }}>
                      Latitude : {loc.origin_latitude}
                    </span>
                  </td>
                  <td>
                    <span style={{ fontSize: "9px" }}>
                      Longitude : {loc.origin_longitude}
                    </span>
                  </td>

                  <td>{new Date(loc.updatedAt).toUTCString()}</td>
                  <td>{loc.User.email}</td>
                  <td>{loc.notes}</td>
                  <td>
                    {loc.imageNotes?.length > 0 ? (
                      <div
                        className="relative mx-auto"
                        style={{ width: "50px" }}
                        onClick={() => {
                          setImageGalleryIsOpen(true);
                          setImageLoc(loc);
                        }}
                      >
                        <img
                          className="img-fluid rounded-1"
                          style={{ height: "50px", width: "100px" }}
                          src={loc.imageNotes[0]}
                          alt="loc view"
                        />
                        <div className="absolute top-0 left-0 h-full w-full | bg-black/25 rounded-1 | flex justify-center align-items-center | text-white fs-5 fw-bold">
                          <p className="h-fit m-0">{loc.imageNotes.length}</p>
                        </div>
                      </div>
                    ) : (
                      <>No Images</>
                    )}
                  </td>
                  {/* <td>{loc.soft_lock? 'Yes' :'No'}</td> */}
                  {/* <td>{loc.hard_lock ? "Yes" : "No"}</td> */}

                  {role !== "user" &&
                    (!loggedInThirdPartyId ||
                      (loggedInThirdPartyId &&
                        loc.user_id === loggedInUserId)) && (
                      <td style={{ paddingBottom: 10, paddingRight: 10 }}>
                        {isBin ? (
                          <>
                            {notUser.includes(role) && (
                              <button
                                className="btn btn-primary p-1 m-o mx-2"
                                type="button"
                                // onClick={(e) =>
                                //   dispatch(
                                //     onDeletingLoc(
                                //       e,
                                //       loc.loc_id,
                                //       token,
                                //       "single"
                                //     )
                                //   )
                                // }
                                onClick={(e) => {
                                  setSingleLocID(loc.loc_id);
                                  setRestoreSingleIsOpen(true);
                                }}
                              >
                                <RecycleBinIcon className="cuser-pointer" />

                                {/* <i className="far fa-trash-alt text-danger"></i> */}
                              </button>
                            )}
                          </>
                        ) : (
                          <>
                            {!loc.soft_lock && (
                              <Link
                                to={"/UpdateSingleLocInfo/" + loc.loc_id}
                                className="btn p-0 m-o"
                                type="button"
                              >
                                <EditIcon className="cursor-pointer" />
                              </Link>
                            )}
                            {notUser.includes(role) && (
                              <button
                                className="btn p-0 m-o mx-2"
                                type="button"
                                // onClick={(e) =>
                                //   dispatch(
                                //     onDeletingLoc(
                                //       e,
                                //       loc.loc_id,
                                //       token,
                                //       "single"
                                //     )
                                //   )
                                // }
                                onClick={(e) => {
                                  setSingleLocID(loc.loc_id);
                                  setDeleteSingleIsOpen(true);
                                }}
                              >
                                <DeleteIcon className="cuser-pointer" />

                                {/* <i className="far fa-trash-alt text-danger"></i> */}
                              </button>
                            )}
                            {/* {notAdminOrUser.includes(role) && (
                            <button
                              className="btn p-0 m-o"
                              type="button"
                              onClick={() => {
                                handleOpenLockModal(loc);
                              }}
                            >
                              <i className="fas fa-lock soft"></i>
                            </button>
                          )} */}
                          </>
                        )}
                      </td>
                    )}
                </tr>
              );
            })
          ) : loadSearch ? (
            <div style={{ textAlign: "center", padding: "20px 0" }}>
              <div className="spinner-border" role="status">
                {/* <span className="sr-only">Loading...</span> */}
              </div>
            </div>
          ) : searchSingleLocs && searchSingleLocs.length > 0 ? (
            searchSingleLocs.map((loc) => {
              return (
                <tr key={loc.loc_id}>
                  <td scope="row">
                    <input
                      id="default-checkbox"
                      type="checkbox"
                      checked={selectedSingleLocs.includes(loc.loc_id)}
                      onChange={() => onSelectLoc(loc.loc_id, "single")}
                      className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 focus:ring-2"
                    />
                  </td>
                  <td>{loc.unique_asset_id}</td>
                  <td>{loc.actual_asset_name}</td>
                  {Object.keys(sortObjectByArray(loc.fields || {}, fields)).map(
                    (field) => (
                      <td key={field}>{loc.fields ? loc.fields[field] : ""}</td>
                    )
                  )}
                  {/* <td className="d-flex justify-content-center">
                                <button
                                  type="button"
                                  className="btn btn-primary btn-sm w-75"
                                >
                                  view
                                </button>
                              </td> */}
                  <td>
                    <span style={{ fontSize: "9px" }}>
                      Latitude : {loc.origin_latitude}
                    </span>
                  </td>
                  <td>
                    <span style={{ fontSize: "9px" }}>
                      Longitude : {loc.origin_longitude}
                    </span>
                  </td>

                  <td>{new Date(loc.updatedAt).toUTCString()}</td>
                  <td>{loc.User.email}</td>
                  <td>{loc.notes}</td>
                  <td>
                    {loc.imageNotes?.length > 0 ? (
                      <div
                        className="relative mx-auto"
                        style={{ width: "50px" }}
                        onClick={() => setImageGalleryIsOpen(true)}
                      >
                        <img
                          className="img-fluid rounded-1"
                          style={{ height: "50px", width: "100px" }}
                          src={loc.imageNotes[0]}
                          alt="loc view"
                        />
                        <div className="absolute top-0 left-0 h-full w-full | bg-black/25 rounded-1 | flex justify-center align-items-center | text-white fs-5 fw-bold">
                          <p className="h-fit m-0">{loc.imageNotes.length}</p>
                        </div>
                      </div>
                    ) : (
                      <>No Images</>
                    )}
                  </td>
                  {/* <td>{loc.soft_lock? 'Yes' :'No'}</td> */}
                  {/* <td>{loc.hard_lock ? "Yes" : "No"}</td> */}
                  {role !== "user" &&
                    (!loggedInThirdPartyId ||
                      (loggedInThirdPartyId &&
                        loc.user_id === loggedInUserId)) && (
                      <td style={{ paddingBottom: 10, paddingRight: 10 }}>
                        {isBin ? (
                          <>
                            {notUser.includes(role) && (
                              <button
                                className="btn btn-primary p-1 m-o mx-2"
                                type="button"
                                // onClick={(e) =>
                                //   dispatch(
                                //     onDeletingLoc(
                                //       e,
                                //       loc.loc_id,
                                //       token,
                                //       "single"
                                //     )
                                //   )
                                // }
                                onClick={(e) => {
                                  setSingleLocID(loc.loc_id);
                                  setRestoreSingleIsOpen(true);
                                }}
                              >
                                <RecycleBinIcon className="cuser-pointer" />

                                {/* <i className="far fa-trash-alt text-danger"></i> */}
                              </button>
                            )}
                          </>
                        ) : (
                          <>
                            {!loc.soft_lock && (
                              <Link
                                to={"/UpdateSingleLocInfo/" + loc.loc_id}
                                className="btn p-0 m-o"
                                type="button"
                              >
                                <EditIcon className="cursor-pointer" />
                              </Link>
                            )}
                            {notUser.includes(role) && (
                              <button
                                className="btn p-0 m-o mx-2"
                                type="button"
                                // onClick={(e) =>
                                //   dispatch(
                                //     onDeletingLoc(
                                //       e,
                                //       loc.loc_id,
                                //       token,
                                //       "single"
                                //     )
                                //   )
                                // }
                                onClick={(e) => {
                                  setSingleLocID(loc.loc_id);
                                  setDeleteSingleIsOpen(true);
                                }}
                              >
                                <DeleteIcon className="cuser-pointer" />

                                {/* <i className="far fa-trash-alt text-danger"></i> */}
                              </button>
                            )}
                            {/* {notAdminOrUser.includes(role) && (
                            <button
                              className="btn p-0 m-o"
                              type="button"
                              onClick={() => {
                                handleOpenLockModal(loc);
                              }}
                            >
                              <i className="fas fa-lock soft"></i>
                            </button>
                          )} */}
                          </>
                        )}
                      </td>
                    )}
                </tr>
              );
            })
          ) : (
            <tr className="">
              <td colSpan={12} className="text-center">
                loc doesn't exist
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <Modal
        isOpen={imageGalleryIsOpen}
        style={customStyles}
        onRequestClose={() => {
          setImageGalleryIsOpen(false);
          setImageIndex(0);
        }}
        contentLabel="Delete Modal"
        overlayClassName={styles.Overlay}
        ariaHideApp={false}
      >
        <div id="locGallery" className="carousel slide" data-bs-ride="carousel">
          <div className="carousel-inner">
            {imageLoc?.imageNotes.map((image, index) => (
              <div
                className={`carousel-item ${
                  index === imageIndex ? "active" : ""
                }`}
                data-bs-interval="99999999999"
              >
                <img
                  src={image}
                  className="img-fluid"
                  style={{ maxHeight: "90vh" }}
                  alt="loc"
                />
              </div>
            ))}
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#locGallery"
            data-bs-slide="prev"
            onClick={() =>
              setImageIndex(
                imageIndex <= 0
                  ? imageLoc?.imageNotes.length - 1
                  : imageIndex - 1
              )
            }
          >
            <span
              className="carousel-control-prev-icon bg-primary rounded-3"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#locGallery"
            data-bs-slide="next"
            onClick={() =>
              setImageIndex(
                imageIndex === imageLoc?.imageNotes.length - 1
                  ? 0
                  : imageIndex + 1
              )
            }
          >
            <span
              className="carousel-control-next-icon bg-primary rounded-3"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
          <div className="text-center py-2">
            <button
              className="btn btn-primary"
              onClick={() => downloadFile(imageLoc.imageNotes[imageIndex])}
            >
              Download
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default AssignedLocsTable;
