import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  onChangeProjectInputs,
  onFetchingSpecificProject,
  onEditingProject,
  onSelectingProject,
} from "../../store/Projects/ProjectsReducer";
import { PropagateLoader } from "react-spinners";
import { css } from "@emotion/react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { notAuthError, url } from "../../constants";
import EditProjectMap from "../Map/EditProjectMap";
import useProjectUsersContainer from "../../containers/useProjectUsersContainer";
import roles from "../../util/roles";
import useThirdParty from "../../hooks/thirdParty/useThirdParty";

const styleLinkBack = {
  textDecoration: "none",
  color: "#717993",
  fontSize: "22px",
};

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
const EditProject = () => {
  const navigate = useNavigate();
  const [disableBtn, setDisableBtn] = useState(false);
  const { id } = useParams();
  const dispatch = useDispatch();
  const { token, userId, role } = useSelector((state) => state.login);

  const [globalIdentifier, setGlobalIdenetifier] = useState(null);
  const [radius, setRadius] = useState(2000);
  const [orgId, setOrgId] = useState(null);

  const { thirdParties, loading: thirdPartiesLoading } = useThirdParty(
    orgId,
    !!orgId
  );

  const [assignedThirdParties, setAssignedThirdParties] = useState([]);

  const { specificProject, projectForm, loadEditProject, loadSpecificPorject } =
    useSelector((state) => state.projects);

  useEffect(() => {
    dispatch(onFetchingSpecificProject(id, token, navigate));
  }, []);

  useEffect(() => {
    if (specificProject) {
      dispatch(onChangeProjectInputs(specificProject.name, "name"));
      dispatch(onChangeProjectInputs(specificProject.latitude, "lat"));
      dispatch(onChangeProjectInputs(specificProject.longitude, "long"));
      dispatch(onChangeProjectInputs(specificProject.radius, "radius"));
      dispatch(onChangeProjectInputs(specificProject.privacy, "privacy"));
    }
  }, [specificProject]);

  const {
    assignedUsers,
    setAssignedUsers,
    onAssignUser,
    users,
    loading: assignedUsersLoading,
  } = useProjectUsersContainer(specificProject?.id);

  const loggedInUser = localStorage.getItem("userId");
  const loggedInThirdParty = localStorage.getItem("thirdParty_id");
  let filteredUsers = users;
  if(loggedInThirdParty) {
    filteredUsers = users.filter(user => loggedInThirdParty === user.thirdParty_id);
  }

  useEffect(() => {
    fetch(`${url}/api/projects/${id}/locations`, {
      headers: { Authorization: `Bearer ${token}`, web: true },
    })
      .then((res) => res.json())
      .then((resData) => {
        if (resData.error) {
          if (resData.error === notAuthError) {
            toast("you session expired, please login again.");
          } else {
            toast.error(resData.error);
          }
        } else {
          setGlobalIdenetifier(resData.globalIdentifier);
          setOrgId(resData.globalIdentifier.org_id);
        }
      });
  }, [id, token]);


  useEffect(() => {
    let assignedUsersData = users.filter(user => assignedUsers.includes(user.user_id));

    const alreadyAssignedThirdParties = assignedUsersData.reduce((acc, user) => {
      if(user.thirdParty_id && !acc.includes(user.thirdParty_id)) {
        acc.push(user.thirdParty_id)
      }
      return acc;
    }, []);

    setAssignedThirdParties(alreadyAssignedThirdParties);
  }, [users, assignedUsers])

  const onAssignThirdParty = (thirdPartyId) => {
    let usersInThirdParty = users.filter(user => user.thirdParty_id === thirdPartyId);
    usersInThirdParty = usersInThirdParty.map(user => {
      return user.user_id
    })
    if(!assignedThirdParties.includes(thirdPartyId)) {
      setAssignedThirdParties([...assignedThirdParties, thirdPartyId]);
      setAssignedUsers(prevState => {
        let updatedState = [...prevState];
        usersInThirdParty.forEach(userId => {
          const index = updatedState.findIndex(item => item === userId);
          if(index > -1) {
            // updatedState.splice(index, 1);
          } else {
            updatedState.push(userId);
          };
        })
        return updatedState;
      })
    } else {
      setAssignedThirdParties(assignedThirdParties.filter((thirdParty) => thirdParty !== thirdPartyId));
      setAssignedUsers(prevState => {
        let updatedState = [...prevState];
        usersInThirdParty.forEach(userId => {
          const index = updatedState.findIndex(item => item === userId);
          if(index > -1) {
            updatedState.splice(index, 1);
          }
          //  else {
          //   updatedState.push(userId);
          // };
        })
        return updatedState;
      })
    }
  };


  return (
    <Fragment>
      <div className="container">
        <ToastContainer />
        {globalIdentifier?.name ? (
          <Link
            to={"/globalidenetifiers/projects/" + globalIdentifier.gid}
            style={styleLinkBack}
          >
            {globalIdentifier.name}
          </Link>
        ) : null}
        {/* <Link to={"/gid"} style={styleLinkBack}>
          GID 1
        </Link>
        <span className="mx-2" style={{ color: "#28345C" }}>
          <i className="fas fa-chevron-right"></i>
          <i className="fas fa-chevron-right"></i>
        </span>
        <Link to={"/gid"} style={styleLinkBack}>
          EDIT Project 1
        </Link> */}
        {loadSpecificPorject ? (
          <div style={{ textAlign: "center" }}>
            <div className="spinner-border" role="status"></div>
          </div>
        ) : (
          <div className="row">
            <div className="col-10 col-md-6 m-auto">
              <h3 className="text-center my-3 text-uppercase">EDIT PROJECT</h3>
              <form
                onSubmit={(e) =>
                  dispatch(
                    onEditingProject(
                      e,
                      id,
                      token,
                      projectForm.name.value,
                      projectForm.lat.value,
                      projectForm.long.value,
                      projectForm.radius.value,
                      projectForm.privacy.value,
                      assignedUsers,
                      navigate
                    )
                  )
                }
              >
                <input
                  type="text"
                  className="form-control"
                  placeholder="Edit Project Name"
                  onChange={(e) =>
                    dispatch(onChangeProjectInputs(e.target.value, "name"))
                  }
                  value={
                    projectForm.name.value !== ""
                      ? projectForm.name.value
                      : specificProject && specificProject.name
                  }
                  style={
                    projectForm.name.valid ? {} : { border: "1px solid red" }
                  }
                />
                {projectForm.name.valid ? null : (
                  <div style={{ color: "red", fontSize: 14 }}>
                    {projectForm.name.validationError}
                  </div>
                )}
                {/* <ProjectsMap radius={radius} /> */}

                <EditProjectMap
                  radius={projectForm.radius.value || specificProject.radius}
                  projectLat={projectForm.lat.value || specificProject.latitude}
                  projectLong={
                    projectForm.long.value || specificProject.longitude
                  }
                />

                <div className="row my-3">
                  <div className="col">
                    <input
                      type="text"
                      className="form-control"
                      name="Latitude"
                      placeholder="Latitude"
                      disabled={true}
                      onChange={(e) =>
                        dispatch(
                          onChangeProjectInputs(Number(e.target.value), "lat")
                        )
                      }
                      value={
                        projectForm.lat.value !== ""
                          ? projectForm.lat.value
                          : specificProject && specificProject.latitude
                      }
                      style={true ? {} : { border: "1px solid red" }}
                    />
                    {true ? null : (
                      <div style={{ color: "red", fontSize: 14 }}>
                        {projectForm.lat.validationError}
                      </div>
                    )}
                  </div>
                  <div className="col">
                    <input
                      type="text"
                      className="form-control"
                      name="Longitude"
                      placeholder="Longitude"
                      disabled={true}
                      onChange={(e) =>
                        dispatch(
                          onChangeProjectInputs(Number(e.target.value), "long")
                        )
                      }
                      value={
                        projectForm.long.value !== ""
                          ? projectForm.long.value
                          : specificProject && specificProject.longitude
                      }
                      style={true ? {} : { border: "1px solid red" }}
                    />
                    {true ? null : (
                      <div style={{ color: "red", fontSize: 14 }}>
                        {projectForm.long.validationError}
                      </div>
                    )}
                  </div>
                </div>
                <div
                  style={{
                    width: "90%",
                    display: "flex",
                    // justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <input
                    type="number"
                    step={1}
                    min={0}
                    className="form-control"
                    name="Radius"
                    placeholder="Radius"
                    id="Radius"
                    onChange={(e) => {
                      setRadius(e.target.value);
                      dispatch(onChangeProjectInputs(e.target.value, "radius"));
                    }}
                    value={
                      projectForm.radius.value !== ""
                        ? projectForm.radius.value
                        : specificProject && specificProject.radius
                    }
                    style={
                      projectForm.radius.valid
                        ? {}
                        : { border: "1px solid red" }
                    }
                  />
                  <span style={{ marginLeft: 5 }}>Meter</span>
                </div>
                {projectForm.radius.valid ? null : (
                  <div style={{ color: "red", fontSize: 14 }}>
                    {projectForm.radius.validationError}
                  </div>
                )}

                <div className="flex items-center mt-2">
                  <input
                    onChange={(e) => {
                      if (projectForm.privacy.value === "private") {
                        dispatch(onChangeProjectInputs("public", "privacy"));
                        if(assignedUsers.includes(loggedInUser) && filteredUsers.some(user => user.user_id === loggedInUser)){
                          onAssignUser(loggedInUser);
                        }
                      } else {
                        dispatch(onChangeProjectInputs("private", "privacy"));
                        if(!assignedUsers.includes(loggedInUser) && filteredUsers.some(user => user.user_id === loggedInUser)){
                          onAssignUser(loggedInUser);
                        }
                      }
                    }}
                    checked={projectForm.privacy.value === "private"}
                    id="checked-checkbox"
                    type="checkbox"
                    className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label
                    htmlFor="checked-checkbox"
                    className="ml-2 text-sm font-medium"
                  >
                    Private
                  </label>
                </div>

                {!assignedUsersLoading &&
                  users.length > 0 &&
                  projectForm.privacy.value === "private" &&
                  specificProject &&
                  (specificProject.User.user_id === userId ||
                    role === roles.super_admin ||
                    role === roles.super_user ||
                    role === roles.saas_admin) && (
                    <div className="mt-4">
                      {thirdParties.length > 0 ? (
                        <>
                          <h6>Assigned Third Parties</h6>

                          <div
                            className="mt-2"
                            style={{ height: "100px", overflowY: "scroll" }}
                          >
                            {thirdParties.map((thirdParty) => (
                              <>
                                {role === roles.saas_admin ||
                                role === roles.super_user ||
                                role === roles.super_admin ? (
                                  <div
                                    key={thirdParty.user_id}
                                    className="flex items-center my-2 mr-2"
                                  >
                                    <input
                                      onChange={() => onAssignThirdParty(thirdParty.id)}
                                      checked={assignedThirdParties.includes(thirdParty.id)}
                                      id={thirdParty.id}
                                      type="checkbox"
                                      className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                      htmlFor={thirdParty.id}
                                      className="ml-2 text-sm font-medium"
                                    >
                                      {thirdParty.name}
                                    </label>
                                  </div>
                                ) : (
                                  <label
                                    htmlFor="checked-checkbox"
                                    className="text-sm font-medium"
                                  >
                                    {projectForm.privacy.value === "private"
                                      ? "Private"
                                      : "Public"}
                                  </label>
                                )}
                              </>
                            ))}
                          </div>
                        </>
                      ) : ""}
                      <h6>Assigned Users</h6>

                      <div
                        className="mt-2"
                        style={{ height: "150px", overflowY: "scroll" }}
                      >
                        {filteredUsers.map((user) => (
                          <>
                            {role === roles.saas_admin ||
                            role === roles.super_user ||
                            role === roles.super_admin ||
                            specificProject.User.user_id === userId ? (
                              <div
                                key={user.user_id}
                                className="flex items-center my-2 mr-2"
                              >
                                <input
                                  onChange={() => onAssignUser(user.user_id)}
                                  checked={assignedUsers.includes(user.user_id)}
                                  id={user.user_id}
                                  type="checkbox"
                                  className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                />
                                <label
                                  htmlFor={user.user_id}
                                  className="ml-2 text-sm font-medium"
                                >
                                  {user.fullName}
                                </label>
                              </div>
                            ) : (
                              <label
                                htmlFor="checked-checkbox"
                                className="text-sm font-medium"
                              >
                                {projectForm.privacy.value === "private"
                                  ? "Private"
                                  : "Public"}
                              </label>
                            )}
                          </>
                        ))}
                      </div>
                    </div>
                  )}

                {users.length === 0 &&
                  projectForm.privacy.value === "private" &&
                  specificProject &&
                  (specificProject.User.user_id === userId ||
                    role === roles.super_admin ||
                    role === roles.super_user ||
                    role === roles.saas_admin) && (
                    <p className="fw-bold my-2 text-center">
                      there are no users in this global idenetifier
                    </p>
                  )}

                <div className="d-flex justify-content-center my-3">
                  <button
                    disabled={
                      disableBtn
                        ? disableBtn
                        : !(
                            projectForm.name.valid &&
                            projectForm.lat.valid &&
                            projectForm.long.valid &&
                            projectForm.radius.valid
                          )
                    }
                    type="submit"
                    className="btn btn-primary w-25"
                    style={{ width: "130px", height: "40px" }}
                    onClick={() => {
                      setTimeout(() => {
                        setDisableBtn(true);
                      }, 1);
                      setTimeout(() => {
                        setDisableBtn(false);
                      }, 2000);
                    }}
                  >
                    {loadEditProject ? (
                      <PropagateLoader
                        color={"#fff"}
                        css={override}
                        size={10}
                      />
                    ) : (
                      "Save"
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
};
export default EditProject;
