import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import {
  onDeletingLoc,
  onFetchingLocs,
  onSearchingLoc,
  onSortingLocs,
  onDeletingSelectedLoc,
  toggleLock,
} from "../../store/Locs/LocsReducer";
import { notAuthError, url } from "../../constants";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";

import Modal from "react-modal";
import styles from "./Project.module.css";
import { notAdminOrUser, notUser } from "../../util/roles";
import SingleLocsTable from "../locs/SingleLocsTable";
import DualLocsTable from "../locs/DualLocsTable";
import DynamicSingleLocsTable from "../locs/DynamicSingleLocsTable";
import DynamicDualLocsTable from "../locs/DynamicDualLocsTable";

const styleLinkBack = {
  textDecoration: "none",
  color: "#717993",
  fontSize: "22px",
};
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const RecycleBin = () => {
  const [RestoreSingleIsOpen, setRestoreSingleIsOpen] = useState(false);
  const [singleLocID, setSingleLocID] = useState("");
  const [RestoreDualIsOpen, setRestoreDualIsOpen] = useState(false);
  const [RestoreMultiIsOpen, setRestoreMultiIsOpen] = useState(false);
  const [RestoreMultiDualIsOpen, setRestoreMultiDualIsOpen] = useState(false);

  const [dualLocID, setDualLocID] = useState("");
  const { id } = useParams();
  const dispatch = useDispatch();
  const { token, role } = useSelector((state) => state.login);

  const [globalIdentifier, setGlobalIdenetifier] = useState(null);
  const [gid, setGid] = useState("");
  const [project, setProject] = useState(null);
  const [location, setLocation] = useState(null);
  const [selectedSingleLocs, setSelectedSingleLocs] = useState([]);
  const [selectedDualLocs, setSelectedDualLocs] = useState([]);
  const organization = useSelector((state) => state.login.org_id);
  const [categories, setCategories] = useState([]);

  const [singleLocs, setSingleLocs] = useState([]);
  const [dualLocs, setDualLocs] = useState([]);
  const [loadingLocs, setLoadingLocs] = useState(true);

  const [selectedLoc, setSelectedLoc] = useState(null);

  const onSelectLoc = (id, type) => {
    if (type === "single") {
      if (selectedSingleLocs.includes(id)) {
        setSelectedSingleLocs(selectedSingleLocs.filter((loc) => loc !== id));
      } else {
        setSelectedSingleLocs([...selectedSingleLocs, id]);
      }
    } else {
      if (selectedDualLocs.includes(id)) {
        setSelectedDualLocs(selectedDualLocs.filter((loc) => loc !== id));
      } else {
        setSelectedDualLocs([...selectedDualLocs, id]);
      }
    }
  };

  const onSelectAllSingleLocs = (arr) => {
    if (selectedSingleLocs.toString() === arr.toString()) {
      setSelectedSingleLocs([]);
    } else {
      setSelectedSingleLocs(arr);
    }
  };

  const onSelectAllDualLocs = (arr) => {
    if (selectedDualLocs.toString() === arr.toString()) {
      setSelectedDualLocs([]);
    } else {
      setSelectedDualLocs(arr);
    }
  };

  const handleCloseModal = () => {
    setSelectedLoc(null);
  };

  useEffect(() => {
    fetch(`${url}/api/locations/${id}`, {
      headers: { Authorization: `Bearer ${token}`, web: true },
    })
      .then((res) => res.json())
      .then((resData) => {
        if (resData.error) {
          if (resData.error === notAuthError) {
            toast("you session expired, please login again.");
          } else {
            toast.error(resData.error);
          }
        } else {
          setGlobalIdenetifier(resData.globalIdentifier);
          setProject(resData.project);
          setLocation(resData.location);
          setGid(resData.globalIdentifier.gid);
        }
      })
      .then((resData) => {
        fetch(`${url}/api/LOCs/bin?location_id=${id}`, {
          headers: { Authorization: `Bearer ${token}`, web: true },
        })
          .then((res) => res.json())
          .then((resData) => {
            const singleLocs = resData.locs.filter(
              (loc) => loc.LOC_type === "single"
            );
            const dualLocs = resData.locs.filter(
              (loc) => loc.LOC_type === "dual"
            );
            setSingleLocs(singleLocs);
            setDualLocs(dualLocs);
            setLoadingLocs(false);
          });
      });
  }, [id, token, loadingLocs]);

  useEffect(() => {
    if (location) {
      fetch(
        `${url}/api/organizations/categories?organization=${organization}&location=${location.id}`,
        {
          headers: { Authorization: `Bearer ${token}`, web: true },
        }
      )
        .then((res) => res.json())
        .then((resData) => {
          if (resData.error) {
            if (resData.error === notAuthError) {
              toast("your session expired, please login again.");
            } else {
              toast.error(resData.error);
            }
          } else {
            setCategories(resData.categories);
            console.log(resData.categories);
          }
        });
    }
  }, [location, organization, token]);

  const [sortType, setSortType] = useState("");
  const [locSorted, setLocSorted] = useState("");

  const sortTable = (locType, sort) => {
    let type = "desc";
    setLocSorted(locType);
    if (sort === sortType) {
      setSortType("");
      type = "asc";
    } else {
      setSortType(sort);
    }
    onSortingLocs(dualLocs, singleLocs, type, sort, locType);
  };

  return (
    <Fragment>
      <div className="container">
        <ToastContainer />

        {globalIdentifier?.name && project?.name && location?.name ? (
          <Fragment>
            <Link to={"/globalidenetifiers"} style={styleLinkBack}>
              Global Identifiers
            </Link>
            <span className="mx-2" style={{ color: "#28345C" }}>
              <i className="fas fa-chevron-right"></i>
              <i className="fas fa-chevron-right"></i>
            </span>
            <Link
              to={"/globalidenetifiers/projects/" + globalIdentifier.gid}
              style={styleLinkBack}
            >
              {globalIdentifier.name}
            </Link>
            <span className="mx-2" style={{ color: "#28345C" }}>
              <i className="fas fa-chevron-right"></i>
              <i className="fas fa-chevron-right"></i>
            </span>
            <Link to={"/locations/" + project.id} style={styleLinkBack}>
              {project.name}
            </Link>
            <span className="mx-2" style={{ color: "#28345C" }}>
              <i className="fas fa-chevron-right"></i>
              <i className="fas fa-chevron-right"></i>
            </span>
            <Link to={"/viewlocs/" + location.id} style={styleLinkBack}>
              {location.name}
            </Link>
            <span className="mx-2" style={{ color: "#28345C" }}>
              <i className="fas fa-chevron-right"></i>
              <i className="fas fa-chevron-right"></i>
            </span>
            <Link to={""} style={styleLinkBack}>
              Recycle Bin
            </Link>
          </Fragment>
        ) : null}

        <div className="row">
          <div className="col-12 m-auto">
            <h3 className="my-3">Deleted LOC’s</h3>
          </div>
          {loadingLocs ? (
            <div style={{ textAlign: "center" }}>
              <div className="spinner-border" role="status">
                {/* <span className="sr-only">Loading...</span> */}
              </div>
            </div>
          ) : singleLocs && singleLocs.length > 0 ? (
            <div className="row col-12">
              <div className="d-flex align-items-center mt-4 justify-content-between">
                <h5 className="mr-2 font-bold mb-3">Single LOC’s</h5>
                {selectedSingleLocs.length !== 0 && (
                  <button
                    onClick={() => setRestoreMultiIsOpen(true)}
                    className="mb-2 text-center rounded py-1 focus:outline-none bg-[#65CC57] border-none text-white"
                  >
                    Restore selected single LOCs
                  </button>
                )}
              </div>
              <div className="table-responsive text-center px-0">
                <div
                  className="accordion accordion-flush border border-bottom-1"
                  id="accordionSingle"
                >
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingSingleOne">
                      <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseSingleOne"
                        aria-expanded="true"
                        aria-controls="collapseSingleOne"
                      >
                        Default
                      </button>
                    </h2>
                    <div
                      id="collapseSingleOne"
                      className="accordion-collapse collapse show"
                      aria-labelledby="headingSingleOne"
                      data-bs-parent="#accordionSingle"
                    >
                      <div
                        className="accordion-body p-0"
                        style={{ maxHeight: 300, overflowY: "auto" }}
                      >
                        <SingleLocsTable
                          role={role}
                          searchSingleLocs={[""]}
                          singleLocs={singleLocs.filter(
                            (loc) => loc.category === null
                          )}
                          selectedSingleLocs={selectedSingleLocs}
                          onSelectLoc={onSelectLoc}
                          onSelectAllSingleLocs={onSelectAllSingleLocs}
                          sortTable={sortTable}
                          sortType={sortType}
                          locSorted={locSorted}
                          renderedItem={"locs"}
                          notUser={notUser}
                          setSingleLocID={setSingleLocID}
                          notAdminOrUser={notAdminOrUser}
                          setRestoreSingleIsOpen={setRestoreSingleIsOpen}
                          isBin={true}
                        />
                      </div>
                    </div>
                  </div>
                  {categories
                    .filter((category) => category.LOC_type === "single")
                    .map((category) => (
                      <div className="accordion-item">
                        <h2
                          className="accordion-header"
                          id={`headingSingle-${category.id}`}
                        >
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#collapseSingle-${category.id}`}
                            aria-expanded="false"
                            aria-controls={`collapseSingle-${category.id}`}
                          >
                            {category.name}
                          </button>
                        </h2>
                        <div
                          id={`collapseSingle-${category.id}`}
                          className="accordion-collapse collapse"
                          aria-labelledby={`headingSingle-${category.id}`}
                          data-bs-parent="#accordionSingle"
                        >
                          <div class="accordion-body p-0">
                            <DynamicSingleLocsTable
                              role={role}
                              searchSingleLocs={[""]}
                              singleLocs={singleLocs.filter(
                                (loc) => loc.category === category.id
                              )}
                              selectedSingleLocs={selectedSingleLocs}
                              onSelectLoc={onSelectLoc}
                              onSelectAllSingleLocs={onSelectAllSingleLocs}
                              sortTable={sortTable}
                              sortType={sortType}
                              locSorted={locSorted}
                              renderedItem={"locs"}
                              notUser={notUser}
                              setSingleLocID={setSingleLocID}
                              notAdminOrUser={notAdminOrUser}
                              setRestoreSingleIsOpen={setRestoreSingleIsOpen}
                              isBin={true}
                              fields={category.fields}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          ) : (
            <h1 style={{ textAlign: "center", margin: "20px 0" }}>
              No Single LOC’s in bin
            </h1>
          )}

          {loadingLocs ? (
            <div style={{ textAlign: "center" }}>
              <div className="spinner-border" role="status">
                {/* <span className="sr-only">Loading...</span> */}
              </div>
            </div>
          ) : dualLocs && dualLocs.length > 0 ? (
            <div className="row col-12">
              <div className="d-flex align-items-center mt-4 justify-content-between">
                <h5 className="mr-2 font-bold mb-3">Dual LOC’s</h5>
                {selectedDualLocs.length !== 0 && (
                  <button
                    onClick={() => setRestoreMultiDualIsOpen(true)}
                    className="mb-2 text-center rounded py-1 focus:outline-none bg-[#65CC57] border-none text-white"
                  >
                    Restore selected dual LOCs
                  </button>
                )}
              </div>
              <div className="table-responsive col-12 text-center pb-5 px-0">
                <div
                  className="accordion accordion-flush border border-bottom-1"
                  id="accordionDual"
                >
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingDualOne">
                      <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseDualOne"
                        aria-expanded="true"
                        aria-controls="collapseDualOne"
                      >
                        Default
                      </button>
                    </h2>
                    <div
                      id="collapseDualOne"
                      className="accordion-collapse collapse show"
                      aria-labelledby="headingDualOne"
                      data-bs-parent="#accordionDual"
                    >
                      <div
                        className="accordion-body p-0"
                        style={{ maxHeight: 300, overflowX: "auto" }}
                      >
                        <DualLocsTable
                          role={role}
                          searchDualLocs={[""]}
                          dualLocs={dualLocs.filter(
                            (loc) => loc.category === null
                          )}
                          selectedDualLocs={selectedDualLocs}
                          onSelectLoc={onSelectLoc}
                          onSelectAllDualLocs={onSelectAllDualLocs}
                          sortTable={sortTable}
                          sortType={sortType}
                          locSorted={locSorted}
                          renderedItem={"locs"}
                          notUser={notUser}
                          setDualLocID={setDualLocID}
                          notAdminOrUser={notAdminOrUser}
                          selectedLoc={selectedLoc}
                          setSelectedLoc={setSelectedLoc}
                          handleCloseModal={handleCloseModal}
                          isBin={true}
                          setRestoreDualIsOpen={setRestoreDualIsOpen}
                        />
                      </div>
                    </div>
                  </div>
                  {categories
                    .filter((category) => category.LOC_type === "dual")
                    .map((category) => (
                      <div className="accordion-item">
                        <h2
                          className="accordion-header"
                          id={`headingDual-${category.id}`}
                        >
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#collapseDual-${category.id}`}
                            aria-expanded="false"
                            aria-controls={`collapseDual-${category.id}`}
                          >
                            {category.name}
                          </button>
                        </h2>
                        <div
                          id={`collapseDual-${category.id}`}
                          className="accordion-collapse collapse"
                          aria-labelledby={`headingDual-${category.id}`}
                          data-bs-parent="#accordionDual"
                        >
                          <div class="accordion-body p-0 overflow-auto">
                            <DynamicDualLocsTable
                              role={role}
                              searchDualLocs={[""]}
                              dualLocs={dualLocs.filter(
                                (loc) => loc.category === category.id
                              )}
                              selectedDualLocs={selectedDualLocs}
                              onSelectLoc={onSelectLoc}
                              onSelectAllDualLocs={onSelectAllDualLocs}
                              sortTable={sortTable}
                              sortType={sortType}
                              locSorted={locSorted}
                              renderedItem={"locs"}
                              notUser={notUser}
                              setDualLocID={setDualLocID}
                              notAdminOrUser={notAdminOrUser}
                              selectedLoc={selectedLoc}
                              setSelectedLoc={setSelectedLoc}
                              handleCloseModal={handleCloseModal}
                              fields={category.fields}
                              destination_fields={category.destination_fields}
                              isBin={true}
                              setRestoreDualIsOpen={setRestoreDualIsOpen}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          ) : (
            <h1 style={{ textAlign: "center", margin: "20px 0" }}>
              No dual LOC’s in bin
            </h1>
          )}
        </div>
      </div>

      {/* Restore Modal Single*/}
      <Modal
        isOpen={RestoreSingleIsOpen}
        style={customStyles}
        onRequestClose={() => setRestoreSingleIsOpen(false)}
        contentLabel="Restore Modal"
        className={styles.Modal}
        overlayClassName={styles.Overlay}
        ariaHideApp={false}
      >
        <div className="container modal-body">
          <div className="row">
            <div className="col-10 m-auto">
              <h5 className="text-center my-3">
                Are you sure you want to restore this?
              </h5>

              <div className="d-flex justify-content-center my-3">
                <button
                  type="button"
                  className="btn btn-success mx-2"
                  onClick={(e) => {
                    fetch(`${url}/api/LOCs/bin/restore/${singleLocID}`, {
                      method: "PATCH",
                      headers: { Authorization: `Bearer ${token}`, web: true },
                    })
                      .then((res) => res.json())
                      .then((resData) => {
                        if (resData.error) {
                          toast(resData.error);
                        } else {
                          toast(resData.message);
                        }
                      });
                    setRestoreSingleIsOpen(false);
                    setLoadingLocs(true);
                  }}
                >
                  Restore
                </button>
                <button
                  type="button"
                  className="btn btn-secondary mx-2"
                  onClick={() => setRestoreSingleIsOpen(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {/* End Restore Modal Single */}

      {/* Restore Modal Dual*/}
      <Modal
        isOpen={RestoreDualIsOpen}
        style={customStyles}
        onRequestClose={() => setRestoreDualIsOpen(false)}
        contentLabel="Restore Modal"
        className={styles.Modal}
        overlayClassName={styles.Overlay}
        ariaHideApp={false}
      >
        <div className="container modal-body">
          <div className="row">
            <div className="col-10 m-auto">
              <h5 className="text-center my-3">
                Are you sure you want to restore this?
              </h5>

              <div className="d-flex justify-content-center my-3">
                <button
                  type="button"
                  className="btn btn-success mx-2"
                  onClick={(e) => {
                    fetch(`${url}/api/LOCs/bin/restore/${dualLocID}`, {
                      method: "PATCH",
                      headers: { Authorization: `Bearer ${token}`, web: true },
                    })
                      .then((res) => res.json())
                      .then((resData) => {
                        if (resData.error) {
                          toast(resData.error);
                        } else {
                          toast(resData.message);
                        }
                      });
                    setRestoreDualIsOpen(false);
                    setLoadingLocs(true);
                  }}
                >
                  Restore
                </button>
                <button
                  type="button"
                  className="btn btn-secondary mx-2"
                  onClick={() => setRestoreDualIsOpen(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {/* End Restore Modal Dual */}

      {/* Restore Multi Modal*/}
      <Modal
        isOpen={RestoreMultiIsOpen}
        style={customStyles}
        onRequestClose={() => setRestoreMultiIsOpen(false)}
        contentLabel="Restore Modal"
        className={styles.Modal}
        overlayClassName={styles.Overlay}
        ariaHideApp={false}
      >
        <div className="container modal-body">
          <div className="row">
            <div className="col-10 m-auto">
              <h5 className="text-center my-3">
                Are you sure you want to restore all selected LOCs?
              </h5>

              <div className="d-flex justify-content-center my-3">
                <button
                  type="button"
                  className="btn btn-success mx-2"
                  onClick={(e) => {
                    fetch(`${url}/api/LOCs/bin/restore/many`, {
                      method: "PATCH",
                      headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                        web: true,
                      },
                      body: JSON.stringify({ locIds: selectedSingleLocs }),
                    })
                      .then((res) => res.json())
                      .then((resData) => {
                        if (resData.error) {
                          toast(resData.error);
                        } else {
                          toast(resData.message);
                        }
                      });
                    setRestoreMultiIsOpen(false);
                    setSelectedSingleLocs([]);
                    setLoadingLocs(true);
                  }}
                >
                  Restore
                </button>
                <button
                  type="button"
                  className="btn btn-secondary mx-2"
                  onClick={() => setRestoreMultiIsOpen(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      {/* Restore Multi dual Modal*/}
      <Modal
        isOpen={RestoreMultiDualIsOpen}
        style={customStyles}
        onRequestClose={() => setRestoreMultiDualIsOpen(false)}
        contentLabel="Restore Modal"
        className={styles.Modal}
        overlayClassName={styles.Overlay}
        ariaHideApp={false}
      >
        <div className="container modal-body">
          <div className="row">
            <div className="col-10 m-auto">
              <h5 className="text-center my-3">
                Are you sure you want to restore all selected LOCs?
              </h5>

              <div className="d-flex justify-content-center my-3">
                <button
                  type="button"
                  className="btn btn-success mx-2"
                  onClick={(e) => {
                    fetch(`${url}/api/LOCs/bin/restore/many`, {
                      method: "PATCH",
                      headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                        web: true,
                      },
                      body: JSON.stringify({ locIds: selectedDualLocs }),
                    })
                      .then((res) => res.json())
                      .then((resData) => {
                        if (resData.error) {
                          toast(resData.error);
                        } else {
                          toast(resData.message);
                        }
                      });
                    setRestoreMultiDualIsOpen(false);
                    setSelectedDualLocs([]);
                    setLoadingLocs(true);
                  }}
                >
                  Restore
                </button>
                <button
                  type="button"
                  className="btn btn-secondary mx-2"
                  onClick={() => setRestoreMultiDualIsOpen(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </Fragment>
  );
};
export default RecycleBin;
