import { Marker as GoogleMarker, InfoWindow } from "@react-google-maps/api";
import unassignedSingleLoc from "../../assets/images/single-unassigned.png";
import assignedSingleLoc from "../../assets/images/single-assigned.png";
import assignedDualLoc from "../../assets/images/dual-assigned.png";
import unassignedDualLoc from "../../assets/images/dual-unassigned.png";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  fieldImages,
  fieldImagesGreen,
  fieldImagesRed,
} from "../../util/constants";

const initFields = { field_1: {}, field_2: {}, field_3: {} };

const Marker = ({
  loc,
  onMarkerDragEnd,
  selectedLegends = [],
  selectedField = "",
  fields = initFields,
  selectedCategory = "",
}) => {
  const typeImage =
    loc.type === "single" && loc.status === "unassigned"
      ? unassignedSingleLoc
      : loc.type === "single" && loc.status === "assigned"
      ? assignedSingleLoc
      : loc.type === "dual" && loc.status === "assigned"
      ? assignedDualLoc
      : unassignedDualLoc;

  const imageStatus =
    loc.status === "assigned" ? fieldImagesGreen : fieldImagesRed;

  const index = selectedField
    ? Object.keys(fields[selectedField]).indexOf(loc[selectedField])
    : 0;

  let image = selectedLegends.includes(loc[selectedField])
    ? imageStatus[index]
    : typeImage;

  if (selectedCategory && selectedCategory.image) {
    image =
      loc.status === "assigned" && selectedCategory.image.includes("black.svg")
        ? selectedCategory.image.replace("_black.svg", "_rgb(93, 180, 63).svg")
        : selectedCategory.image.replace("_black.svg", "_rgb(202, 75, 69).svg");
  }

  const [isInfoWindowOpen, setIsInfoWindowOpen] = useState(false);
  const navigate = useNavigate();

  const navigateToLoc = (loc) => {
    if (loc.type === "single") {
      navigate(`/UpdateSingleLocInfo/${loc.loc_id}`);
    } else if (loc.type === "dual") {
      navigate(`/UpdateDualLocInfo/${loc.loc_id}`);
    }
  };

  const iconSize = new window.google.maps.Size(20, 20);
  const iconAnchor = new window.google.maps.Point(10, 10);

  const handleHoverIn = () => {
    setIsInfoWindowOpen(true);
  };

  const handleHoverOut = () => {
    setIsInfoWindowOpen(false);
  };

  return (
    <>
      <GoogleMarker
        position={{
          lat: loc.latitude,
          lng: loc.longitude,
        }}
        icon={{
          url: image,
          scaledSize: iconSize,
          anchor: iconAnchor,
        }}
        onMouseOver={handleHoverIn}
        onMouseOut={handleHoverOut}
        onDragEnd={(e) => onMarkerDragEnd(e)}
        onClick={(e) => navigateToLoc(loc)}
      >
        {isInfoWindowOpen && (
          <InfoWindow>
            <p style={{ marginBottom: 0, fontWeight: "600" }}>
              unique asset id : {loc.unique_asset_id}
            </p>
          </InfoWindow>
        )}
      </GoogleMarker>
    </>
  );
};

export default Marker;
