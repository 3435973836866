import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams, useLocation } from "react-router-dom";
import {
  onDeletingLoc,
  onFetchingLocs,
  onResetingDualLocForm,
  onResetingSingleLocForm,
  onSearchingLoc,
  onSortingLocs,
  onDeletingSelectedLoc,
  toggleLock,
} from "../../store/Locs/LocsReducer";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { ExcelRenderer } from "react-excel-renderer";

import Modal from "react-modal";
import styles from "./../project/Project.module.css";

import { notAuthError, url } from "../../constants";
import { notAdminOrUser, notUser } from "../../util/roles";
import SingleLocsTable from "../locs/SingleLocsTable";
import DualLocsTable from "../locs/DualLocsTable";
import DynamicSingleLocsTable from "../locs/DynamicSingleLocsTable";
import DynamicDualLocsTable from "../locs/DynamicDualLocsTable";

// const styleLinkBack = {
//   textDecoration: "none",
//   color: "#717993",
//   fontSize: "22px",
// };
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "20px",
  },
};
const UnAssignedLocs = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  let searchType = queryParams.keys().next().value; // This gets the first key (LOCSearchType)
  let searchQuery = queryParams.get(searchType);
  const [DeleteSingleIsOpen, setDeleteSingleIsOpen] = useState(false);
  const [singleLocID, setSingleLocID] = useState("");
  const [DeleteDualIsOpen, setDeleteDualIsOpen] = useState(false);
  const [DeleteMultiIsOpen, setDeleteMultiIsOpen] = useState(false);
  const [DeleteMultiDualIsOpen, setDeleteMultiDualIsOpen] = useState(false);

  const [dualLocID, setDualLocID] = useState("");
  const dispatch = useDispatch();
  const [gid, setGid] = useState("");
  const [selectedSingleLocs, setSelectedSingleLocs] = useState([]);
  const [selectedDualLocs, setSelectedDualLocs] = useState([]);
  const [searchValue, setSearchValue] = useState(searchQuery);
  const [LOCSearchType, setLOCSearchType] = useState(searchType);

  const [softLockOpen, setSoftLockOpen] = useState(false);
  const [selectedLoc, setSelectedLoc] = useState(null);

  const onSelectLoc = (id, type) => {
    if (type === "single") {
      if (selectedSingleLocs.includes(id)) {
        setSelectedSingleLocs(selectedSingleLocs.filter((loc) => loc !== id));
      } else {
        setSelectedSingleLocs([...selectedSingleLocs, id]);
      }
    } else {
      if (selectedDualLocs.includes(id)) {
        setSelectedDualLocs(selectedDualLocs.filter((loc) => loc !== id));
      } else {
        setSelectedDualLocs([...selectedDualLocs, id]);
      }
    }
  };

  const onSelectAllSingleLocs = (arr) => {
    if (selectedSingleLocs.toString() === arr.toString()) {
      setSelectedSingleLocs([]);
    } else {
      setSelectedSingleLocs(arr);
    }
  };

  const onSelectAllDualLocs = (arr) => {
    if (selectedDualLocs.toString() === arr.toString()) {
      setSelectedDualLocs([]);
    } else {
      setSelectedDualLocs(arr);
    }
  };

  const handleOpenLockModal = (loc) => {
    setSoftLockOpen(true);
    setSelectedLoc(loc);
  };

  const handleCloseModal = () => {
    setSoftLockOpen(false);
    setSelectedLoc(null);
  };

  const handleToggleSoftLock = () => {
    dispatch(
      toggleLock(
        selectedLoc.loc_id,
        selectedLoc.LOC_type,
        token,
        handleCloseModal
      )
    );
  };

  const { token, role } = useSelector((state) => state.login);

  const [flag, setFlag] = useState(false);

  const [singleLocs, setSingleLocs] = useState([]);
  const [dualLocs, setDualLocs] = useState([]);
  const [searchSingleLocs, setSearchSingleLocs] = useState([]);
  const [searchDualLocs, setSearchDualLocs] = useState([]);
  const [loadingLocs, setLoadingLocs] = useState(true);
  const [renderedItem, setRenderedItem] = useState("locs");
  const [loadSearch, setLoadSearch] = useState(false);
  const [categories, setCategories] = useState([]);
  const organization = useSelector((state) => state.login.org_id);

  const searchForLOCs = () => {
    fetch(`${url}/api/LOCs/all?${searchType}=${searchQuery}`, {
      method: "GET",
      headers: { Authorization: `Bearer ${token}`, web: true },
    })
      .then((res) => res.json())
      .then((resData) => {
        if (resData.error) {
          if (resData.error === notAuthError) {
            toast("you session expired, please login again.");
          } else {
            toast.error(resData.error);
          }
        } else {
          setSingleLocs(() => {
            return resData.locs.filter((loc) => loc.LOC_type === "single");
          });
          setDualLocs(() => {
            return resData.locs.filter((loc) => loc.LOC_type === "dual");
          });
          setLoadingLocs(false);
        }
      });
  };

  useEffect(() => {
    searchForLOCs();
  }, []);

  useEffect(() => {
    if (location) {
      fetch(
        `${url}/api/organizations/categories?${
          role !== "saas admin" ? `organization=${organization}` : ""
        }`,
        {
          headers: { Authorization: `Bearer ${token}`, web: true },
        }
      )
        .then((res) => res.json())
        .then((resData) => {
          if (resData.error) {
            if (resData.error === notAuthError) {
              toast("your session expired, please login again.");
            } else {
              toast.error(resData.error);
            }
          } else {
            setCategories(resData.categories);
          }
        });
    }
  }, [token]);

  const [sortType, setSortType] = useState("");
  const [locSorted, setLocSorted] = useState("");

  const sortTable = (locType, sort) => {
    let type = "desc";
    setLocSorted(locType);
    if (sort === sortType) {
      setSortType("");
      type = "asc";
    } else {
      setSortType(sort);
    }
    onSortingLocs(dualLocs, singleLocs, type, sort, locType);
  };

  return (
    <Fragment>
      <div className="container">
        <ToastContainer />

        <div className="row">
          <div className="col-12 m-auto">
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div
                className="w-75 m-auto my-4"
                style={{ position: "relative" }}
              >
                <i
                  className="far fa-search text-dark"
                  style={{ position: "absolute", top: "30%", left: "1%" }}
                ></i>
                <input
                  style={{ paddingLeft: 30 }}
                  type="text"
                  className="form-control"
                  name="search"
                  placeholder="Search by..."
                  value={searchValue}
                  onChange={(e) => {
                    setSearchValue(e.target.value);
                    searchQuery = searchValue;
                  }}
                />
              </div>

              <div className="inline-block relative w-64 ml-2">
                <select
                  value={LOCSearchType}
                  onChange={(e) => {
                    setLOCSearchType(e.target.value);
                    searchType = LOCSearchType;
                  }}
                  className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded  leading-tight focus:outline-none focus:shadow-outline"
                >
                  <option value="unique_asset_id">
                    Unique Asset ID / Route ID
                  </option>
                  <option value="actual_asset_name">
                    Unique Asset Name / Origin
                  </option>
                  <option value="field_1">FIELD 1</option>
                  <option value="field_2">FIELD 2</option>
                  <option value="field_3">FIELD 3</option>
                  <option value="notes">NOTES</option>
                  <option value="email">LAST UPDATED BY</option>
                  <option value="destination">DESTINATION</option>
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg
                    className="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div>
              <Link
                className={`${
                  searchValue === "" ? "bg-gray-300" : "bg-[#0d6efd]"
                } rounded-3 px-3 py-2 text-white fw-bold ms-2`}
                to={`/globalsearch?${LOCSearchType}=${searchValue}`}
                onClick={() => {
                  setLoadingLocs(true);
                  searchType = LOCSearchType;
                  searchQuery = searchValue;
                  searchForLOCs();
                }}
                style={{
                  pointerEvents: searchValue === "" ? "none" : "auto",
                  cursor: searchValue === "" ? "not-allowed" : "pointer",
                }}
              >
                Search
              </Link>
            </div>
          </div>
          {loadingLocs ? (
            <div style={{ textAlign: "center" }}>
              <div className="spinner-border" role="status">
                {/* <span className="sr-only">Loading...</span> */}
              </div>
            </div>
          ) : singleLocs && singleLocs.length > 0 ? (
            <div className="row">
              <div className="d-flex align-items-center mt-4 justify-content-between">
                <h5 className="mr-2 font-bold mb-3">Single LOC’s</h5>
                {selectedSingleLocs.length !== 0 && (
                  <button
                    onClick={() => setDeleteMultiIsOpen(true)}
                    className="mb-2 text-center rounded py-1 focus:outline-none bg-danger border-none text-white"
                  >
                    Delete selected single LOCs
                  </button>
                )}
              </div>
              <div className="table-responsive text-center px-0">
                <div
                  className="accordion accordion-flush border border-bottom-1"
                  id="accordionSingle"
                >
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingSingleOne">
                      <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseSingleOne"
                        aria-expanded="true"
                        aria-controls="collapseSingleOne"
                      >
                        Default
                      </button>
                    </h2>
                    <div
                      id="collapseSingleOne"
                      className="accordion-collapse collapse show"
                      aria-labelledby="headingSingleOne"
                      data-bs-parent="#accordionSingle"
                    >
                      <div
                        className="accordion-body p-0"
                        style={{ maxHeight: 400, overflowY: "auto" }}
                      >
                        <SingleLocsTable
                          role={role}
                          searchSingleLocs={searchSingleLocs}
                          searchValue={searchValue}
                          singleLocs={singleLocs.filter(
                            (loc) => loc.category === null
                          )}
                          selectedSingleLocs={selectedSingleLocs}
                          onSelectLoc={onSelectLoc}
                          onSelectAllSingleLocs={onSelectAllSingleLocs}
                          sortTable={sortTable}
                          sortType={sortType}
                          locSorted={locSorted}
                          renderedItem={renderedItem}
                          notUser={notUser}
                          setSingleLocID={setSingleLocID}
                          setDeleteSingleIsOpen={setDeleteSingleIsOpen}
                          handleOpenLockModal={handleOpenLockModal}
                          notAdminOrUser={notAdminOrUser}
                          loadSearch={loadSearch}
                          globalSearch={true}
                        />
                      </div>
                    </div>
                  </div>
                  {categories
                    .filter((category) => category.LOC_type === "single")
                    .map((category) => (
                      <div className="accordion-item">
                        <h2
                          className="accordion-header"
                          id={`headingSingle-${category.id}`}
                        >
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#collapseSingle-${category.id}`}
                            aria-expanded="false"
                            aria-controls={`collapseSingle-${category.id}`}
                          >
                            {category.name}
                          </button>
                        </h2>
                        <div
                          id={`collapseSingle-${category.id}`}
                          className="accordion-collapse collapse"
                          aria-labelledby={`headingSingle-${category.id}`}
                          data-bs-parent="#accordionSingle"
                        >
                          <div class="accordion-body p-0">
                            <DynamicSingleLocsTable
                              role={role}
                              searchSingleLocs={searchSingleLocs}
                              searchValue={searchValue}
                              singleLocs={singleLocs.filter(
                                (loc) => loc.category === category.id
                              )}
                              selectedSingleLocs={selectedSingleLocs}
                              onSelectLoc={onSelectLoc}
                              onSelectAllSingleLocs={onSelectAllSingleLocs}
                              sortTable={sortTable}
                              sortType={sortType}
                              locSorted={locSorted}
                              renderedItem={renderedItem}
                              notUser={notUser}
                              setSingleLocID={setSingleLocID}
                              setDeleteSingleIsOpen={setDeleteSingleIsOpen}
                              handleOpenLockModal={handleOpenLockModal}
                              notAdminOrUser={notAdminOrUser}
                              loadSearch={loadSearch}
                              fields={category.fields}
                              globalSearch={true}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          ) : (
            <h1 style={{ textAlign: "center", margin: "20px 0" }}>
              No Single LOC’s
            </h1>
          )}

          {loadingLocs ? (
            <div style={{ textAlign: "center" }}>
              <div className="spinner-border" role="status">
                {/* <span className="sr-only">Loading...</span> */}
              </div>
            </div>
          ) : dualLocs && dualLocs.length > 0 ? (
            <div className="row">
              <div className="d-flex align-items-center mt-4 justify-content-between">
                <h5 className="mr-2  font-bold mb-3">Dual LOC’s</h5>
                {selectedDualLocs.length !== 0 && (
                  <button
                    onClick={() => setDeleteMultiDualIsOpen(true)}
                    className="mb-2 text-center rounded py-1 focus:outline-none bg-danger border-none text-white"
                  >
                    Delete selected dual LOCs
                  </button>
                )}
              </div>
              <div className="table-responsive col-12 text-center pb-5 px-0">
                <div
                  className="accordion accordion-flush border border-bottom-1"
                  id="accordionDual"
                >
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingDualOne">
                      <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseDualOne"
                        aria-expanded="true"
                        aria-controls="collapseDualOne"
                      >
                        Default
                      </button>
                    </h2>
                    <div
                      id="collapseDualOne"
                      className="accordion-collapse collapse show"
                      aria-labelledby="headingDualOne"
                      data-bs-parent="#accordionDual"
                    >
                      <div
                        className="accordion-body p-0"
                        style={{ maxHeight: 400, overflowX: "auto" }}
                      >
                        <DualLocsTable
                          role={role}
                          searchDualLocs={searchDualLocs}
                          searchValue={""}
                          dualLocs={dualLocs.filter(
                            (loc) => loc.category === null
                          )}
                          selectedDualLocs={selectedDualLocs}
                          onSelectLoc={onSelectLoc}
                          onSelectAllDualLocs={onSelectAllDualLocs}
                          sortTable={sortTable}
                          sortType={sortType}
                          locSorted={locSorted}
                          renderedItem={renderedItem}
                          notUser={notUser}
                          setDualLocID={setDualLocID}
                          setDeleteDualIsOpen={setDeleteDualIsOpen}
                          notAdminOrUser={notAdminOrUser}
                          loadSearch={loadSearch}
                          handleOpenLockModal={handleOpenLockModal}
                          softLockOpen={softLockOpen}
                          selectedLoc={selectedLoc}
                          setSoftLockOpen={setSoftLockOpen}
                          setSelectedLoc={setSelectedLoc}
                          handleCloseModal={handleCloseModal}
                          handleToggleSoftLock={handleToggleSoftLock}
                          globalSearch={true}
                        />
                      </div>
                    </div>
                  </div>
                  {categories
                    .filter((category) => category.LOC_type === "dual")
                    .map((category) => (
                      <div className="accordion-item">
                        <h2
                          className="accordion-header"
                          id={`headingDual-${category.id}`}
                        >
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#collapseDual-${category.id}`}
                            aria-expanded="false"
                            aria-controls={`collapseDual-${category.id}`}
                          >
                            {category.name}
                          </button>
                        </h2>
                        <div
                          id={`collapseDual-${category.id}`}
                          className="accordion-collapse collapse"
                          aria-labelledby={`headingDual-${category.id}`}
                          data-bs-parent="#accordionDual"
                        >
                          <div class="accordion-body p-0 overflow-auto">
                            <DynamicDualLocsTable
                              role={role}
                              searchDualLocs={searchDualLocs}
                              searchValue={""}
                              dualLocs={dualLocs.filter(
                                (loc) => loc.category === category.id
                              )}
                              selectedDualLocs={selectedDualLocs}
                              onSelectLoc={onSelectLoc}
                              onSelectAllDualLocs={onSelectAllDualLocs}
                              sortTable={sortTable}
                              sortType={sortType}
                              locSorted={locSorted}
                              renderedItem={renderedItem}
                              notUser={notUser}
                              setDualLocID={setDualLocID}
                              setDeleteDualIsOpen={setDeleteDualIsOpen}
                              notAdminOrUser={notAdminOrUser}
                              loadSearch={loadSearch}
                              handleOpenLockModal={handleOpenLockModal}
                              softLockOpen={softLockOpen}
                              selectedLoc={selectedLoc}
                              setSoftLockOpen={setSoftLockOpen}
                              setSelectedLoc={setSelectedLoc}
                              handleCloseModal={handleCloseModal}
                              handleToggleSoftLock={handleToggleSoftLock}
                              fields={category.fields}
                              destination_fields={category.destination_fields}
                              globalSearch={true}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          ) : (
            <h1 style={{ textAlign: "center", margin: "20px 0" }}>
              No dual LOC’s
            </h1>
          )}
        </div>
      </div>
      {/* Delete Modal Single*/}
      <Modal
        isOpen={DeleteSingleIsOpen}
        style={customStyles}
        onRequestClose={() => setDeleteSingleIsOpen(false)}
        contentLabel="Delete Modal"
        className={styles.Modal}
        overlayClassName={styles.Overlay}
        ariaHideApp={false}
      >
        <div className="container modal-body">
          <div className="row">
            <div className="col-10 m-auto">
              <h5 className="text-center my-3">
                Are you sure you want to delete this?
              </h5>

              <div className="d-flex justify-content-center my-3">
                <button
                  type="button"
                  className="btn btn-danger mx-2"
                  onClick={(e) => {
                    dispatch(
                      onDeletingLoc(e, singleLocID, token, "single", gid)
                    );
                    setDeleteSingleIsOpen(false);
                  }}
                >
                  Delete
                </button>
                <button
                  type="button"
                  className="btn btn-secondary mx-2"
                  onClick={() => setDeleteSingleIsOpen(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {/* End Delete Modal Single */}

      {/* Delete Modal Dual*/}
      <Modal
        isOpen={DeleteDualIsOpen}
        style={customStyles}
        onRequestClose={() => setDeleteDualIsOpen(false)}
        contentLabel="Delete Modal"
        className={styles.Modal}
        overlayClassName={styles.Overlay}
        ariaHideApp={false}
      >
        <div className="container modal-body">
          <div className="row">
            <div className="col-10 m-auto">
              <h5 className="text-center my-3">
                Are you sure you want to delete this?
              </h5>

              <div className="d-flex justify-content-center my-3">
                <button
                  type="button"
                  className="btn btn-danger mx-2"
                  onClick={(e) => {
                    dispatch(onDeletingLoc(e, dualLocID, token, "dual", gid));
                    setDeleteDualIsOpen(false);
                  }}
                >
                  Delete
                </button>
                <button
                  type="button"
                  className="btn btn-secondary mx-2"
                  onClick={() => setDeleteDualIsOpen(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {/* End Delete Modal Dual */}

      {/* Delete Multi Modal*/}
      <Modal
        isOpen={DeleteMultiIsOpen}
        style={customStyles}
        onRequestClose={() => setDeleteMultiIsOpen(false)}
        contentLabel="Delete Modal"
        className={styles.Modal}
        overlayClassName={styles.Overlay}
        ariaHideApp={false}
      >
        <div className="container modal-body">
          <div className="row">
            <div className="col-10 m-auto">
              <h5 className="text-center my-3">
                Are you sure you want to delete all selected LOCs?
              </h5>

              <div className="d-flex justify-content-center my-3">
                <button
                  type="button"
                  className="btn btn-danger mx-2"
                  onClick={(e) => {
                    dispatch(
                      onDeletingSelectedLoc(
                        e,
                        selectedSingleLocs,
                        [],
                        dualLocs,
                        singleLocs,
                        token,
                        gid
                      )
                    );
                    setDeleteMultiIsOpen(false);
                    setSelectedSingleLocs([]);
                  }}
                >
                  Delete
                </button>
                <button
                  type="button"
                  className="btn btn-secondary mx-2"
                  onClick={() => setDeleteMultiIsOpen(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      {/* Delete Multi Dual Modal*/}
      <Modal
        isOpen={DeleteMultiDualIsOpen}
        style={customStyles}
        onRequestClose={() => setDeleteMultiDualIsOpen(false)}
        contentLabel="Delete Modal"
        className={styles.Modal}
        overlayClassName={styles.Overlay}
        ariaHideApp={false}
      >
        <div className="container modal-body">
          <div className="row">
            <div className="col-10 m-auto">
              <h5 className="text-center my-3">
                Are you sure you want to delete all selected LOCs?
              </h5>

              <div className="d-flex justify-content-center my-3">
                <button
                  type="button"
                  className="btn btn-danger mx-2"
                  onClick={(e) => {
                    dispatch(
                      onDeletingSelectedLoc(
                        e,
                        [],
                        selectedDualLocs,
                        dualLocs,
                        singleLocs,
                        token,
                        gid
                      )
                    );
                    setDeleteMultiDualIsOpen(false);
                    setSelectedDualLocs([]);
                  }}
                >
                  Delete
                </button>
                <button
                  type="button"
                  className="btn btn-secondary mx-2"
                  onClick={() => setDeleteMultiDualIsOpen(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </Fragment>
  );
};
export default UnAssignedLocs;
