import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { notAuthError, url } from "../../constants";
import roles, { isHeigherPermission } from "../../util/roles";
import { onSelectEditUser } from "../../store/AddUsers/AddUsers";

import Tooltip from "../Tooltip";
import UpgradeBtn from "../upgradeBtn";
import { ReactComponent as LogsIcon } from "../../ui/icons/log.svg";
import { ReactComponent as EditIcon } from "../../ui/icons/EditIcon.svg";
import { ReactComponent as DeleteIcon } from "../../ui/icons/DeleteIcon.svg";

function UsersTable({
  users,
  handleUpgrade,
  setDeleteIsOpen,
  setEditIsOpen,
  setUserID,
}) {
  const [suspendedUsers, setSuspendedUsers] = useState({});
  const { userId, role, token } = useSelector((state) => state.login);

  const handleSuspend = (id) => {
    return new Promise((resolve, reject) => {
      fetch(`${url}/api/users/${id}/suspend`, {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          web: true,
        },
      })
        .then((res) => {
          return res.json();
        })
        .then((resData) => {
          if (resData.error) {
            if (resData.error === notAuthError) {
              toast("you session expired, please login again.");
              resolve("error");
            } else {
              toast.error(resData.error);
              resolve("error");
            }
          }

          if (resData.error && resData.error[0]) {
            toast(resData.error[0].message);
          }
          if (resData.message) {
            toast.success(resData.message);
            resolve("success");
          }
        });
    });
  };

  const handleSuspendClick = (userId) => {
    setSuspendedUsers((prev) => ({ ...prev, [userId]: !prev[userId] }));
    handleSuspend(userId);
  };

  useEffect(() => {
    const suspendedUsers = users?.filter((user) => user.suspend);
    const initialSuspendedStatus = suspendedUsers?.reduce((acc, user) => {
      acc[user.user_id] = user.suspend;
      return acc;
    }, {});
    setSuspendedUsers(initialSuspendedStatus);
  }, [users]);

  const dispatch = useDispatch();

  function capitalizeAndHyphenate(str) {
    const capitalizedWords = str
      .split(" ")
      .map(
        (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
      );

    return capitalizedWords.join("-");
  }

  return (
    <div
      className="table-responsive mt-4 "
      style={{ maxHeight: window.innerHeight - 360 }}
    >
      <table className="table table-borderless">
        <thead className="sticky top-0 z-50 ">
          <tr className="bg-primary text-white rounded-t-lg">
            <th scope="col">Name</th>
            <th scope="col">Email</th>
            <th scope="col">Role</th>
            <th scope="col">Organization</th>
            <th scope="col">Group</th>
            <th scope="col">Available Tags</th>
            <th scope="col" className="text-center">
              Actions
            </th>
          </tr>
        </thead>

        {users && users.length > 0 ? (
          users.map((user) => {
            return user ? (
              <tbody key={user.user_id}>
                <tr>
                  <td>{user.fullName}</td>
                  <td className="w-fit">{user.email}</td>

                  <td className="text-nowrap">
                    {capitalizeAndHyphenate(user.role)}
                  </td>
                  <td>{user?.organization?.name}</td>
                  <td className=" text-nowrap">
                    {user?.thirdParty?.name || "N/A"}
                  </td>
                  <td>
                    <div className="flex flex-row justify-between ">
                      <div className=" text-nowrap ">
                        {user.tags
                          ? `(${user.available_tags} of ${user.tags})`
                          : "Empty Wallet"}
                      </div>
                      {((role === roles.admin && user.user_id === userId) ||
                        (role === roles.super_admin &&
                          user.user_id === userId) ||
                        role === roles.saas_admin) && (
                        <div className=" ">
                          <UpgradeBtn
                            handleUpgrade={handleUpgrade}
                            id={user.user_id}
                          />
                        </div>
                      )}
                    </div>
                  </td>
                  <td className="flex flex-row text-start justify-start min-w-fit items-center my-1  gap-2 ">
                    <Tooltip text="View Logs">
                      <Link to={"/userslogs/" + user.user_id}>
                        <LogsIcon className="cursor-pointer h-5 w-5" />
                      </Link>
                    </Tooltip>
                    {(isHeigherPermission(role, user.role) ||
                      user.user_id === userId) && (
                      <Tooltip text="Edit User">
                        <button
                          className="btn z-10 p-0 m-0"
                          type="button"
                          onClick={() => {
                            dispatch(onSelectEditUser(user.user_id));
                            setEditIsOpen(true);
                          }}
                        >
                          <EditIcon className="cursor-pointer" />
                        </button>
                      </Tooltip>
                    )}
                    {(isHeigherPermission(role, user.role) ||
                      user.user_id === userId) && (
                      <Tooltip text="Delete User">
                        <button
                          className="btn p-0 m-0"
                          type="button"
                          onClick={() => {
                            setUserID(user.user_id);
                            setDeleteIsOpen(true);
                          }}
                        >
                          <DeleteIcon className="cursor-pointer" />
                        </button>
                      </Tooltip>
                    )}
                    {isHeigherPermission(role, user.role) && (
                      <Tooltip
                        text={
                          suspendedUsers[user.user_id]
                            ? "Unsuspend User"
                            : "Suspend User"
                        }
                      >
                        <button
                          className={`btn ${
                            suspendedUsers[user.user_id]
                              ? "btn-danger"
                              : "btn-primary"
                          } btn-sm px-1 py-0`}
                          onClick={() => handleSuspendClick(user.user_id)}
                        >
                          {suspendedUsers[user.user_id]
                            ? "Unsuspend"
                            : "Suspend"}
                        </button>
                      </Tooltip>
                    )}
                  </td>
                </tr>
              </tbody>
            ) : (
              <div style={{ textAlign: "center" }}>No Users Added Yet.</div>
            );
          })
        ) : (
          <tr className="">
            <td colSpan={7} className="text-center">
              user doesn't exist
            </td>
          </tr>
        )}
      </table>
    </div>
  );
}

export default UsersTable;
