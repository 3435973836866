import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  onAddingSingleLoc,
  onChangeLocsInputs,
} from "../../store/Locs/LocsReducer";

import { PropagateLoader } from "react-spinners";
import { css } from "@emotion/react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { notAuthError, url } from "../../constants";
import LOCMap from "../Map/LOCMap";

const styleLinkBack = {
  textDecoration: "none",
  color: "#717993",
  fontSize: "22px",
};

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const CreateSingleLocInfo = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [disableBtn, setDisableBtn] = useState(false);
  const { id } = useParams();
  const { singleLocForm, loading } = useSelector((state) => state.locs);
  const token = useSelector((state) => state.login.token);
  // const { selectedIdentifier } = useSelector((state) => state.globalIdentifier);
  // const { selectedEditProject } = useSelector((state) => state.projects);
  // const { selectedEditLocation } = useSelector((state) => state.locations);

  const [globalIdentifier, setGlobalIdenetifier] = useState(null);
  const [project, setProject] = useState(null);
  const [location, setLocation] = useState(null);
  const [gid, setGid] = useState("");
  const [radius, setRadius] = useState(10);
  const organization = localStorage.getItem("org_id"); 
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState({});

  useEffect(() => {
    fetch(`${url}/api/locations/${id}`, {
      headers: { Authorization: `Bearer ${token}`, web: true },
    })
      .then((res) => res.json())
      .then((resData) => {
        if (resData.error) {
          if (resData.error === notAuthError) {
            toast("you session expired, please login again.");
          } else {
            toast.error(resData.error);
          }
        } else {
          setGlobalIdenetifier(resData.globalIdentifier);
          setProject(resData.project);
          setLocation(resData.location);
          setGid(resData.globalIdentifier.gid);
        }
      });
  }, [id, token, organization]);

  useEffect(() => {
    if(location) {
      fetch(`${url}/api/organizations/categories?organization=${organization}&LOC_type=single`, {
        headers: { Authorization: `Bearer ${token}`, web: true },
      })
      .then((res) => res.json())
      .then((resData) => {
        if (resData.error) {
          if (resData.error === notAuthError) {
            toast("you session expired, please login again.");
          } else {
            toast.error(resData.error);
          }
        } else {
          setCategories(resData.categories);
        }
      });
    }
  },[location, token, organization]);
  return (
    <Fragment>
      <div className="container">
        <ToastContainer />
        {globalIdentifier?.name && project?.name && location?.name ? (
          <Fragment>
          <Link
            to={"/globalidenetifiers"}
            style={styleLinkBack}
          >
            Global Identifiers
          </Link>
          <span className="mx-2" style={{ color: "#28345C" }}>
            <i className="fas fa-chevron-right"></i>
            <i className="fas fa-chevron-right"></i>
          </span>
            <Link
              to={"/globalidenetifiers/projects/" + globalIdentifier.gid}
              style={styleLinkBack}
            >
              {globalIdentifier.name}
            </Link>
            <span className="mx-2" style={{ color: "#28345C" }}>
              <i className="fas fa-chevron-right"></i>
              <i className="fas fa-chevron-right"></i>
            </span>
            <Link to={"/locations/" + project.id} style={styleLinkBack}>
              {project.name}
            </Link>
            <span className="mx-2" style={{ color: "#28345C" }}>
              <i className="fas fa-chevron-right"></i>
              <i className="fas fa-chevron-right"></i>
            </span>
            <Link to={"/viewlocs/" + location.id} style={styleLinkBack}>
              {location.name}
            </Link>
          </Fragment>
        ) : null}

        <div className="row">
          <div className="col-12 m-auto">
            <h3 className="text-center mt-3">Create single LOC</h3>
            <form
              className="form-horizontal row mt-3"
              onSubmit={(e) =>
                dispatch(
                  onAddingSingleLoc(
                    e,
                    token,
                    singleLocForm.unique_asset_id.value,
                    singleLocForm.actual_asset_name.value,
                    singleLocForm.filed1.value,
                    singleLocForm.filed2.value,
                    singleLocForm.filed3.value,
                    singleLocForm.origin_latitude.value,
                    singleLocForm.origin_longitude.value,
                    singleLocForm.origin_radius.value,
                    singleLocForm.notes.value,
                    "single",
                    0,
                    0,
                    id,
                    singleLocForm.origin_status.value,
                    gid,
                    singleLocForm.category.value,
                    singleLocForm.fields.value,
                    navigate
                  )
                )
              }
            >
              <div>
                <div className="mb-3 m-auto col-md-3">
                  <label 
                    className="form-label"
                    style={{ color: "#0987B1" }}
                  >
                    Category
                  </label>
                  <select 
                    className="form-control"
                    name="Category"
                    id="Category"
                    placeholder="Category"
                    onChange={(e) => {
                      if (e.target.value !== "") {
                        dispatch(onChangeLocsInputs(e.target.value, "category"));
                        const category = categories.find((category) => category.id === e.target.value);
                        setSelectedCategory(category);
                        dispatch(onChangeLocsInputs("N/A", "filed1"));
                        dispatch(onChangeLocsInputs("N/A", "filed2"));
                        dispatch(onChangeLocsInputs("N/A", "filed3"));
                        dispatch(onChangeLocsInputs(Object.fromEntries(category.fields.map(item => [item, ''])), "fields"));
                      } else {
                        dispatch(onChangeLocsInputs({}, "fields"));
                        dispatch(onChangeLocsInputs("", "filed1"));
                        dispatch(onChangeLocsInputs("", "filed2"));
                        dispatch(onChangeLocsInputs("", "filed3"));
                        dispatch(onChangeLocsInputs(null, "category"));
                      }
                    }}
                    value={singleLocForm.category.value}
                    style={
                      singleLocForm.category.valid
                        ? {}
                        : { border: "1px solid red" }
                    }
                  >
                      <option value="">Default</option>
                      {categories.length !== 0 && categories.map((category) => (
                        <option value={category.id}>{category.name}</option>
                      ))}
                  </select>

                  {singleLocForm.category.valid ? (
                    <div style={{ color: "white", fontSize: 14 }}>
                      ""
                    </div>
                  ) : (
                    <div style={{ color: "red", fontSize: 14 }}>
                      {singleLocForm.category.validationError}
                    </div>
                  )}
                </div>
              </div>
              <div className="mb-3 m-auto col-md-6">
                <label
                  htmlFor="unique_asset_id"
                  className="form-label"
                  style={{ color: "#0987B1" }}
                >
                  Unique Asset ID
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="unique_asset_id"
                  id="unique_asset_id"
                  placeholder="Unique Asset ID"
                  onChange={(e) =>
                    dispatch(
                      onChangeLocsInputs(e.target.value, "unique_asset_id")
                    )
                  }
                  value={singleLocForm.unique_asset_id.value}
                  style={
                    singleLocForm.unique_asset_id.valid
                      ? {}
                      : { border: "1px solid red" }
                  }
                />
                {singleLocForm.unique_asset_id.valid ? (
                  <div style={{ color: "white", fontSize: 14 }}>
                    ""
                  </div>
                ) : (
                  <div style={{ color: "red", fontSize: 14 }}>
                    {singleLocForm.unique_asset_id.validationError}
                  </div>
                )}
              </div>
              <div className="mb-3 m-auto col-md-6">
                <label
                  htmlFor="actual_asset_name"
                  className="form-label"
                  style={{ color: "#0987B1" }}
                >
                  Actual Asset Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="actual_asset_name"
                  id="actual_asset_name"
                  placeholder="Actual Asset Name"
                  onChange={(e) =>
                    dispatch(
                      onChangeLocsInputs(e.target.value, "actual_asset_name")
                    )
                  }
                  value={singleLocForm.actual_asset_name.value}
                  style={
                    singleLocForm.actual_asset_name.valid
                      ? {}
                      : { border: "1px solid red" }
                  }
                />
                {singleLocForm.actual_asset_name.valid ? (
                  <div style={{ color: "white", fontSize: 14 }}>
                    ""
                  </div>
                ) : (
                  <div style={{ color: "red", fontSize: 14 }}>
                    {singleLocForm.actual_asset_name.validationError}
                  </div>
                )}
              </div>
              <div className="mb-3 mt-0 col-md-6">
              <label
                  htmlFor="notes"
                  className="form-label"
                  style={{ color: "#0987B1" }}
                >
                  Notes
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="notes"
                  id="notes"
                  placeholder="Notes"
                  onChange={(e) =>
                    dispatch(onChangeLocsInputs(e.target.value, "notes"))
                  }
                  value={singleLocForm.notes.value}
                />
              </div>
              <div className="px-0 py-2 rounded roudnded-3 col-md-12">
              <label
                  className="form-label"
                  style={{ color: "#0987B1", fontSize: 30 }}
                >
                  Fields
                </label>
                <div className="bg-light rounded rounded-5 py-4 px-5 overflow-y-auto" style={{height: selectedCategory?.fields?.length > 4 ?'300px' : '150px'}}>
                {singleLocForm.category.value === null ? (
                  <div className="row justify-content-start">
                    <div className="mb-3 col-md-6">
                      <label
                        htmlFor="Field1"
                        className="form-label"
                        style={{ color: "#0987B1" }}
                      >
                        Field 1
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="Field1"
                        id="Field1"
                        placeholder="Field 1"
                        onChange={(e) =>
                          dispatch(onChangeLocsInputs(e.target.value, "filed1"))
                        }
                        value={singleLocForm.filed1.value}
                        style={
                          singleLocForm.filed1.valid
                            ? {}
                            : { border: "1px solid red" }
                        }
                      />
                      {singleLocForm.filed1.valid ? (
                        <div style={{ color: "white", fontSize: 14 }}>
                          ""
                        </div>
                      ) : (
                        <div style={{ color: "red", fontSize: 14 }}>
                          {singleLocForm.filed1.validationError}
                        </div>
                      )}
                    </div>
                    <div className="mb-3 col-md-6">
                      <label
                        htmlFor="Field2"
                        className="form-label"
                        style={{ color: "#0987B1" }}
                      >
                        Field 2
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="Field2"
                        id="Field2"
                        placeholder="Field 2"
                        onChange={(e) =>
                          dispatch(onChangeLocsInputs(e.target.value, "filed2"))
                        }
                        value={singleLocForm.filed2.value}
                        style={
                          singleLocForm.filed2.valid
                            ? {}
                            : { border: "1px solid red" }
                        }
                      />
                      {singleLocForm.filed2.valid ? (
                        <div style={{ color: "white", fontSize: 14 }}>
                          ""
                        </div>
                      ) : (
                        <div style={{ color: "red", fontSize: 14 }}>
                          {singleLocForm.filed2.validationError}
                        </div>
                      )}
                    </div>
                    <div className="mb-3 col-md-6">
                    <label
                        htmlFor="Field3"
                        className="form-label"
                        style={{ color: "#0987B1" }}
                      >
                        Field 3
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="Field3"
                        id="Field3"
                        placeholder="Field 3"
                        onChange={(e) =>
                          dispatch(onChangeLocsInputs(e.target.value, "filed3"))
                        }
                        value={singleLocForm.filed3.value}
                        style={
                          singleLocForm.filed3.valid
                            ? {}
                            : { border: "1px solid red" }
                        }
                      />
                      {singleLocForm.filed3.valid ? (
                        <div style={{ color: "white", fontSize: 14 }}>
                          ""
                        </div>
                      ) : (
                        <div style={{ color: "red", fontSize: 14 }}>
                          {singleLocForm.filed3.validationError}
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="row justify-content-start">
                  {selectedCategory?.fields?.map((field) => (
                    <div className="mb-3 col-md-6">
                    <label
                      htmlFor={field.name}
                      className="form-label"
                      style={{ color: "#0987B1" }}
                    >
                      {field}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name={field}
                      id={field}
                      placeholder={field}
                      onChange={(e) =>
                        dispatch(onChangeLocsInputs(e.target.value, field, true))
                      }
                      value={singleLocForm.fields[field]}
                      style={
                        singleLocForm.fields.valid
                          ? {}
                          : { border: "1px solid red" }
                      }
                    />
                    {singleLocForm.fields.valid ? (
                      <div style={{ color: "white", fontSize: 14 }}>
                        ""
                      </div>
                    ) : (
                      <div style={{ color: "red", fontSize: 14 }}>
                        {singleLocForm.fields.validationError}
                      </div>
                    )}
                  </div>
                  ))}
                  </div>
                )}
              </div>
              </div>
              <div className="mb-3 m-auto col-md-12">
                <h5 className="text-center my-3">LOC Origin Coordinates</h5>
                {location && (
                  <>
                    <LOCMap
                      radius={radius}
                      locationLat={location.latitude}
                      locationLong={location.longitude}
                      onChange={onChangeLocsInputs}
                      origin_coordinates={true}
                    />

                    <div className="row my-3">
                      <div className="col">
                        <input
                          type="text"
                          className="form-control"
                          name="Latitude"
                          placeholder="Latitude"
                          disabled={true}
                          onChange={(e) => {
                            dispatch(
                              onChangeLocsInputs(
                                Number(e.target.value),
                                "origin_latitude"
                              )
                            );
                          }}
                          value={singleLocForm.origin_latitude.value}
                          style={
                            singleLocForm.origin_latitude.valid
                              ? {}
                              : { border: "1px solid red" }
                          }
                        />
                        {true ? null : (
                          <div style={{ color: "red", fontSize: 14 }}>
                            {singleLocForm.origin_latitude.validationError}
                          </div>
                        )}
                      </div>
                      <div className="col">
                        <input
                          type="text"
                          className="form-control"
                          name="Longitude"
                          placeholder="Longitude"
                          disabled={true}
                          onChange={(e) =>
                            dispatch(
                              onChangeLocsInputs(
                                Number(e.target.value),
                                "origin_longitude"
                              )
                            )
                          }
                          value={singleLocForm.origin_longitude.value}
                          style={
                            singleLocForm.origin_longitude.valid
                              ? {}
                              : { border: "1px solid red" }
                          }
                        />
                        {true ? null : (
                          <div style={{ color: "red", fontSize: 14 }}>
                            {singleLocForm.origin_longitude.validationError}
                          </div>
                        )}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "90%",
                        display: "flex",
                        // justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <input
                        type="number"
                        step={1}
                        min={0}
                        className="form-control"
                        name="Radius"
                        placeholder="Radius"
                        id="Radius"
                        onChange={(e) => {
                          dispatch(
                            onChangeLocsInputs(e.target.value, "origin_radius")
                          );
                          setRadius(e.target.value);
                        }}
                        value={
                          singleLocForm.origin_radius.value
                            ? singleLocForm.origin_radius.value
                            : 1
                        }
                        // style={
                        //   dualLocForm.radius.valid
                        //     ? {}
                        //     : { border: "1px solid red" }
                        // }
                      />
                      <span style={{ marginLeft: 5 }}>Meter</span>
                    </div>
                  </>
                )}
              </div>
              {/* <div className="mb-3 m-auto col-8 col-md-8">
                <label
                  htmlFor="Field2"
                  className="form-label"
                  style={{ color: "#0987B1" }}
                >
                  Status
                </label>
                <select
                  id="select"
                  className="form-select mt-1 p-2"
                  onChange={(e) =>
                    dispatch(onChangeLocsInputs(e.target.value, "status"))
                  }
                  value={singleLocForm.origin_status.value}
                >

                  <option value="unassigned" selected="selected">Unassigned</option>
                  <option value="assigned">Assigned</option>
                </select>
              </div> */}

              <div className="d-flex justify-content-center mb-4">
                <button
                  disabled={
                    disableBtn
                      ? disableBtn
                      : !(
                          (
                            singleLocForm.unique_asset_id.valid &&
                            singleLocForm.actual_asset_name.valid &&
                            singleLocForm.filed1.valid &&
                            singleLocForm.filed2.valid &&
                            singleLocForm.filed3.valid
                          )
                          // singleLocForm.status.valid
                        )
                  }
                  type="submit"
                  className="btn btn-primary w-25"
                  style={{ width: "130px", height: "40px" }}
                  onClick={() => {
                    setTimeout(() => {
                      setDisableBtn(true);
                    }, 1);
                    setTimeout(() => {
                      setDisableBtn(false);
                    }, 2000);
                  }}
                >
                  {loading ? (
                    <PropagateLoader color={"#fff"} css={override} size={10} />
                  ) : (
                    "Create"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default CreateSingleLocInfo;
