import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  onAddingGlobalIdentifier,
  onChangeAddGlobalIdentifierInput,
  onFetchingGlobalIdentifiers,
} from "../../store/Globalidenetifiers/Globalidenetifiers";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PropagateLoader } from "react-spinners";
import { css } from "@emotion/react";
import { useNavigate } from "react-router-dom";
import useOrganizations from "../../hooks/useOrganizations";
import roles from "../../util/roles";
import useThirdParty from "../../hooks/thirdParty/useThirdParty";
import useOrgUsers from "../../hooks/gid/useOrgUsers";
import { notAuthError, url } from "../../constants";
import { mergeArrays } from "../../util/mergeArrays";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const AddNewGlobalIdenetifiers = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [disableBtn, setDisableBtn] = useState(false);
  const { token, role } = useSelector((state) => state.login);
  const { globalIdentifierName, loading } = useSelector(
    (state) => state.globalIdentifier
  );
  const { loading: organizationsLoading, organizations } = useOrganizations();
  const [selectedOrg, setSelectedOrg] = useState("");

  const { thirdParties, loading: thirdPartiesLoading } = useThirdParty(
    selectedOrg,
    !!selectedOrg
  );

  const { loading: usersLoading, users } = useOrgUsers(selectedOrg);
  const [assignedUsers, setAssignedUsers] = useState([]);
  const onAssignUser = (userId) => {
    if (!assignedUsers.includes(userId)) {
      setAssignedUsers([...assignedUsers, userId]);
    } else {
      setAssignedUsers(assignedUsers.filter((user) => user !== userId));
    }
  };

  useEffect(() => {
    dispatch(onFetchingGlobalIdentifiers(token));
  }, []);

  const [selectedThirdParties, setSelectedThirdParties] = useState([]);

  const onSelectThirdParties = (id) => {
    if (!selectedThirdParties.includes(id)) {
      setSelectedThirdParties([...selectedThirdParties, id]);
    } else {
      setSelectedThirdParties((prev) =>
        prev.filter((thirdPartyId) => thirdPartyId !== id)
      );
    }
  };

  const getUsersInThirdParties = (thirdPartyIds) => {
    const promises = thirdPartyIds.map((thirdPartyId) => {
      const newUrl = `${url}/api/thirdparties/${thirdPartyId}/users`;
      return fetch(newUrl, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          web: true,
        },
      })
        .then((res) => res.json())
        .then((resData) => {
          if (resData.error) {
            if (resData.error === notAuthError) {
              toast("Your session expired, please login again.");
              return [];
            } else {
              toast.error(resData.error);
              return [];
            }
          } else {
            const users = resData.thirdPartyUsers.map((user) => user.user_id);
            return users;
          }
        });
    });

    return Promise.all(promises);
  };

  return (
    <Fragment>
      <div className="container">
        <ToastContainer />
        <div className="row">
          <div className="col-12 col-md-10 m-auto">
            <h3 className="text-center mt-5">Add new Global Identifiers</h3>
            <form
              className="form-horizontal mt-4 row"
              onSubmit={async (e) => {
                e.preventDefault();
                const thirdPartyUsers = [];
                const userArrays = await getUsersInThirdParties(
                  selectedThirdParties
                );

                userArrays.forEach((users, index) => {
                  thirdPartyUsers.push(...users);
                });

                const newAssignedUsers = mergeArrays(
                  assignedUsers,
                  thirdPartyUsers
                );

                dispatch(
                  onAddingGlobalIdentifier(
                    e,
                    token,
                    {
                      name: globalIdentifierName.name.value,
                      org_id: globalIdentifierName.org_id.value,
                      privacy: globalIdentifierName.privacy.value,
                    },
                    newAssignedUsers,
                    navigate
                  )
                );
              }}
            >
              <div className="mb-3 m-auto col-12 row justify-center">
                <div className="col-6">
                  <input
                    type="text"
                    className="form-control"
                    name="Name"
                    placeholder="Enter Global Identifier here…"
                    onChange={(e) =>
                      dispatch(
                        onChangeAddGlobalIdentifierInput(e.target.value, "name")
                      )
                    }
                    value={globalIdentifierName.name.value}
                    style={
                      globalIdentifierName.name.valid
                        ? { borderColor: "#ccc" }
                        : { borderColor: "red" }
                    }
                  />

                  {globalIdentifierName.name.valid ? null : (
                    <div style={{ color: "red", fontSize: 14 }}>
                      {globalIdentifierName.name.validationError}
                    </div>
                  )}

                <div className="flex items-center mt-3">
                  <input
                    onChange={(e) => {
                      if (globalIdentifierName.privacy.value === "private") {
                        dispatch(
                          onChangeAddGlobalIdentifierInput("public", "privacy")
                        );
                      } else {
                        dispatch(
                          onChangeAddGlobalIdentifierInput("private", "privacy")
                        );
                      }
                    }}
                    checked={globalIdentifierName.privacy.value === "private"}
                    id="checked-checkbox"
                    type="checkbox"
                    className="w-5 h-5 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label
                    htmlFor="checked-checkbox"
                    className="ml-2 text-sm font-medium"
                  >
                    Private
                  </label>
                </div>
                </div>
                  {!organizationsLoading &&
                    organizations &&
                    role === roles.saas_admin && (
                      <div className="col-6">
                        <select
                          id="select1"
                          className="form-select p-2"
                          onChange={(e) => {
                            dispatch(
                              onChangeAddGlobalIdentifierInput(
                                e.target.value,
                                "org_id"
                              )
                            );
                            setSelectedOrg(e.target.value);
                          }}
                          value={globalIdentifierName.org_id.value}
                          style={
                            globalIdentifierName.org_id.valid
                              ? { borderColor: "#ccc" }
                              : { borderColor: "red" }
                          }
                        >
                          <option disabled value="">
                            Organization
                          </option>
                          {organizations.map((organization) => (
                            <option value={organization.id}>
                              {organization.name}
                            </option>
                          ))}
                        </select>
                        {globalIdentifierName.org_id.valid ? null : (
                          <div style={{ color: "red", fontSize: 14 }}>
                            {globalIdentifierName.org_id.validationError}
                          </div>
                        )}
                      </div>
                    )}
              </div>

              {!thirdPartiesLoading &&
                thirdParties.length === 0 &&
                globalIdentifierName.privacy.value === "private" &&
                (role === roles.super_admin ||
                  role === roles.super_user ||
                  role === roles.saas_admin) && (
                  <p className="fw-bold my-2 text-center">
                    there are no third party in this global idenetifier
                  </p>
                )}

              {thirdPartiesLoading &&
              selectedOrg &&
              globalIdentifierName.privacy.value === "private" &&
              (role === roles.super_admin ||
                role === roles.super_user ||
                role === roles.saas_admin) ? (
                <p>loading...</p>
              ) : (
                thirdParties.length > 0 &&
                selectedOrg &&
                globalIdentifierName.privacy.value === "private" &&
                (role === roles.super_admin ||
                  role === roles.super_user ||
                  role === roles.saas_admin) && (
                  <div className="mt-4">
                    <h6>Third Parties</h6>
                    <div
                      className="mt-2"
                      style={{ height: "150px", overflowY: "scroll" }}
                    >
                      {thirdParties.map((thirdParty) => (
                        <>
                          {role === roles.saas_admin ||
                          role === roles.super_user ||
                          role === roles.super_admin ? (
                            <div
                              key={thirdParty.id}
                              className="flex items-center my-2 mr-2"
                            >
                              <input
                                onChange={() =>
                                  onSelectThirdParties(thirdParty.id)
                                }
                                checked={selectedThirdParties.includes(
                                  thirdParty.id
                                )}
                                id={thirdParty.id}
                                type="checkbox"
                                className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                              />
                              <label
                                htmlFor={thirdParty.id}
                                className="ml-2 text-sm font-medium"
                              >
                                {thirdParty.name}
                              </label>
                            </div>
                          ) : (
                            <label
                              htmlFor="checked-checkbox"
                              className="text-sm font-medium"
                            >
                              {globalIdentifierName.privacy.value === "private"
                                ? "Private"
                                : "Public"}
                            </label>
                          )}
                        </>
                      ))}
                    </div>
                  </div>
                )
              )}

              {users.length > 0 &&
                !usersLoading &&
                selectedOrg &&
                globalIdentifierName.privacy.value === "private" &&
                (role === roles.super_admin ||
                  role === roles.super_user ||
                  role === roles.saas_admin) && (
                  <div className="mt-4">
                    <h6>Assigned Users</h6>
                    <div
                      className="mt-2"
                      style={{ height: "150px", overflowY: "scroll" }}
                    >
                      {users.map((user) => (
                        <>
                          {role === roles.saas_admin ||
                          role === roles.super_user ||
                          role === roles.super_admin ? (
                            <div
                              key={user.user_id}
                              className="flex items-center my-2 mr-2"
                            >
                              <input
                                onChange={() => onAssignUser(user.user_id)}
                                checked={assignedUsers.includes(user.user_id)}
                                id={user.user_id}
                                type="checkbox"
                                className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                              />
                              <label
                                htmlFor={user.user_id}
                                className="ml-2 text-sm font-medium"
                              >
                                {user.fullName}
                              </label>
                            </div>
                          ) : (
                            <label
                              htmlFor="checked-checkbox"
                              className="text-sm font-medium"
                            >
                              {globalIdentifierName.privacy.value === "private"
                                ? "Private"
                                : "Public"}
                            </label>
                          )}
                        </>
                      ))}
                    </div>
                  </div>
                )}

              {users.length === 0 &&
                selectedOrg &&
                globalIdentifierName.privacy.value === "private" &&
                (role === roles.super_admin ||
                  role === roles.super_user ||
                  role === roles.saas_admin) && (
                  <p className="fw-bold my-2 text-center">
                    there are no users in this global idenetifier
                  </p>
                )}

              <div className="row mt-4">
                <div className="col-9 col-md-6 m-auto">
                  <div className="d-flex justify-content-center ">
                    <button
                      disabled={
                        disableBtn
                          ? disableBtn
                          : !globalIdentifierName.name.valid
                      }
                      type="submit"
                      className="btn btn-primary w-75"
                      style={{ width: "130px", height: "40px" }}
                      onClick={() => {
                        setTimeout(() => {
                          setDisableBtn(true);
                        }, 1);
                        setTimeout(() => {
                          setDisableBtn(false);
                        }, 2000);
                      }}
                    >
                      {loading ? (
                        <PropagateLoader
                          color={"#fff"}
                          css={override}
                          size={10}
                        />
                      ) : (
                        "Save"
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default AddNewGlobalIdenetifiers;
